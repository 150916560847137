import React from "react";
import ReactDOM from "react-dom";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import createHistory from "history/createBrowserHistory";
import {
  connectRouter,
  routerMiddleware,
  ConnectedRouter,
} from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import T from "i18n-react";
import thunkMiddleware from "redux-thunk";
import promiseMiddleware from "redux-promise-middleware";
import registerServiceWorker from "./registerServiceWorker";
import translations from "./locales";
import { reducer as notifications } from "react-notification-system-redux";
import reducers from "./reducers";
import {
  loadingBarReducer,
  loadingBarMiddleware,
} from "react-redux-loading-bar";
import { createWebSocket } from "./actions/notification";
import "./styles/components/components.css";
import App from "./components/App";
import jQuery from "jquery";
window.jQuery = window.$ = jQuery;
window.fancybox = require("fancybox")(jQuery);

export const history = createHistory();
const middleware = [
  thunkMiddleware,
  routerMiddleware(history),
  promiseMiddleware(),
  loadingBarMiddleware(),
];

export const store = createStore(
  combineReducers({
    ...reducers,
    loadingBar: loadingBarReducer,
    notifications: notifications,
    router: connectRouter(history),
  }),
  applyMiddleware(...middleware)
);

function run() {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </div>
      </ConnectedRouter>
    </Provider>,
    document.getElementById("root")
  );
  registerServiceWorker();
}

function init() {
  let defaultLanguage = "se";
  if (window.localStorage && window.localStorage.language) {
    defaultLanguage = window.localStorage.language;
  }
  T.setTexts(translations[defaultLanguage]);
  run();
  store.subscribe(run);
}

init();

if (window.localStorage && window.localStorage.userId) {
  store.dispatch(createWebSocket(window.localStorage.userId));
}
