const webSocket = (
  state = { updateSocket: null, reconnecting: null },
  action
) => {
  switch (action.type) {
    case "UPDATE_WEBSOCKET":
      return {
        updateSocket: action.updateSocket
      };
    default:
      return state;
  }
};

export default webSocket;
