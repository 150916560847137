export const ARGB2HexFilter = (color) => {
  return "#" + ("000000" + (color & 0xffffff).toString(16)).slice(-6);
};

export const Hex2ARGBFilter = color => {
  if (7 !== color.length) {
    return 0;
  }

  return parseInt("FF" + color.substr(1), 16);
};
