import APIUtils from "../utils/APIUtils";
import config from "../config";
import { store } from "../index";
import uuid from "../utils/uuid.js";

export const RECEIVE_ORDER_ACTIVITY = "RECEIVE_ORDER_ACTIVITY";
export const queryNotifications = (userId, companyId, limit, offset) => {
  if (!companyId) {
    return {
      type: "QUERY_NOTIFICATIONS",
      payload: APIUtils.get(
        "/users/" +
          userId +
          "/notifications?limit=" +
          limit +
          "&offset=" +
          offset
      )
    };
  } else {
    return {
      type: "QUERY_NOTIFICATIONS",
      payload: APIUtils.get(
        "/companies/" +
          companyId +
          "/notifications?limit=" +
          limit +
          "&offset=" +
          offset
      )
    };
  }
};

export const markAllRead = userId => {
  return APIUtils.put("/users/" + userId + "/notifications", {});
};

export const markAsRead = notification => {
  return APIUtils.put(
    "/companies/" +
      notification.companyId +
      "/notifications/" +
      notification.id,
    notification
  );
};

export const createWebSocket = userId => dispatch => {
  let updateSocket = store.getState().updateSocket;
  if (window["WebSocket"]) {
    if (updateSocket) {
      updateSocket.close();
    }

    updateSocket = new WebSocket(
      config.API_BASE
        .replace("http://", "ws://")
        .replace("https://", "wss://") +
        "/webapp-updates?userId=" +
        userId
    );

    updateSocket.onerror = function() {
      if (window.console) {
        window.console.log("Websocket error:", arguments);
      }
    };

    updateSocket.onclose = function(e) {
      if (window.localStorage.userId) {
        setTimeout(function() {
          createWebSocket(window.localStorage.userId);
        }, 5000);
      }
    };

    updateSocket.onmessage = function(data) {
      var message = JSON.parse(data.data);
      if (message) {
        if (message.notification) {
          dispatch({
            type: "RECEIVE_NEW_NOTIFICATION",
            message: message.notification
          });
        }

        if (message.event === "SEND_ORDER_MESSAGE") {
          dispatch({
            type: RECEIVE_ORDER_ACTIVITY,
            event: uuid()
          });
        }

        if (message.event === "SEND_READ_NOTIFICATION") {
          dispatch({
            type: "UPDATE_READ_NOTIFICATION",
            message: true
          });
        }
      }
    };
  }
};
