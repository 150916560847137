import {NUM_COMPANIES_PER_PAGE} from '../../actions/company/SelectCompany';

const SelectCompany = (state, action) => {
    switch (action.type) {
        case "QUERY_COMPANIES_FULFILLED":
            let hasMore = false;
            if (action.payload.body && action.payload.body.length === NUM_COMPANIES_PER_PAGE + 1) {
                action.payload.body.pop();
                hasMore = true;
            }
            return {
                ...state,
                companies: action.payload.body,
                hasMore: hasMore
            };

        case "GET_COMPANY_IMAGE_FULFILLED":
            return {
                ...state,
                companyImage: action.payload
            };

        case "QUERY_PUBLICATION_COLLABRATIONS_FULFILLED":
            return {
                ...state,
                collabrations: action.payload.body
            };

        case "SELECTED_COMPANY":
            return {
                ...state,
                selectedCompany: action.selectedCompany
            };

        default:
            return state || [];
    }
};

export default SelectCompany;
