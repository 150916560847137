import APIUtils from '../../utils/APIUtils'
import config from '../../config'

export const NUM_COMPANIES_PER_PAGE = 10;

export const queryCompanies = (q) => ({
    type: 'QUERY_COMPANIES',
    payload: APIUtils.get('/companies?' + q)
})

export const queryPublicationCollabrations = () => ({
    type: 'QUERY_PUBLICATION_COLLABRATIONS',
    payload: APIUtils.get('/publication-collabrations')
})

export const companyImage = () => ({
    type: 'GET_COMPANY_IMAGE',
    payload: APIUtils.get('/files/startpage_300').then((r) => {
        if (r.status === 200) {
            return config.API_BASE + '/files/startpage_300'
        }
        return null
    })
})
