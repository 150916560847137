const Loading = (state, action) => {
  switch (action.type) {
  	case "LOGIN_PROGRESS":
    case "loading-bar/SHOW":
      return true;
    case "LOGIN_SUCCESS":
    case "LOGIN_FAILURE":
    case "loading-bar/HIDE":
      return false;
    default:
      return state || false;
  }
};

export default Loading;