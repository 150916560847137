import React, { Component } from "react";
import APIUtils from "../utils/APIUtils";

export class CompanyLogo extends Component {
  constructor(props) {
    super(props);
    this.getCompanyLogo = this.getCompanyLogo.bind(this);
    this.state = {
      logo: null
    };
  }

  componentWillMount() {
    this.getCompanyLogo(this.props.companyId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.companyId !== nextProps.companyId) {
      this.getCompanyLogo(nextProps.companyId);
    }
  }

  getCompanyLogo = companyId => {
    APIUtils.get("/companies/" + companyId + "/logo").then(r => {
      if (this.refs["company-logo-" + companyId]) {
        this.setState({
          logo: r.body
        });
      }
    });
  };

  render() {
    return (
      <div ref={"company-logo-" + this.props.companyId}>
        {this.state.logo
          ? <img src={this.state.logo.data} alt="" />
          : this.props.showDefaultLogo
            ? <img
                src="/images/logos/logo-horizontal-rbg-white.png"
                alt="Dokiv logotype"
              />
            : null}
      </div>
    );
  }
}

export default CompanyLogo;
