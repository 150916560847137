import APIUtils from "../../../utils/APIUtils";
import qs from "qs";

export const QUERY_COMPANY_FLOW_VERIFIES = "QUERY_COMPANY_FLOW_VERIFIES";
export const GET_COMPANY_FLOW_VERIFY = "GET_COMPANY_FLOW_VERIFY";
export const CREATE_COMPANY_FLOW_VERIFY = "CREATE_COMPANY_FLOW_VERIFY";
export const UPDATE_COMPANY_FLOW_VERIFY = "UPDATE_COMPANY_FLOW_VERIFY";
export const DELETE_COMPANY_FLOW_VERIFY = "DELETE_COMPANY_FLOW_VERIFY";

export const queryCompanyFlowVerifies = (companyId, filter = {}) => {
	let params = filter;

	return {
		type: QUERY_COMPANY_FLOW_VERIFIES,
		payload: APIUtils.get(
			`/companies/${companyId}/flow-verifies?${qs.stringify(params)}`
		),
	};
};

export const createCompanyFlowVerify = (companyId, flowVerify) => ({
	type: CREATE_COMPANY_FLOW_VERIFY,
	payload: APIUtils.post(`/companies/${companyId}/flow-verifies`, flowVerify),
});

export const getCompanyFlowVerify = (companyId, flowVerifyId) => ({
	type: GET_COMPANY_FLOW_VERIFY,
	payload: APIUtils.get(`/companies/${companyId}/flow-verifies/${flowVerifyId}`),
});

export const updateCompanyFlowVerify = (companyId, flowVerify) => ({
	type: UPDATE_COMPANY_FLOW_VERIFY,
	payload: APIUtils.put(`/companies/${companyId}/flow-verifies/${flowVerify.id}`, flowVerify),
});

export const deleteCompanyFlowVerify = (companyId, flowVerifyId) => ({
	type: DELETE_COMPANY_FLOW_VERIFY,
	payload: APIUtils.del(`/companies/${companyId}/flow-verifies/${flowVerifyId}`),
});

