import APIUtils from '../../../utils/APIUtils'
import qs from "qs"
let LIMIT = 20

export const queryCompanyUsers = (companyId, params = {}) => {
  let { page, ...query } = params;
  query.limit = LIMIT + 1;
  query.offset = 0;
  if (parseInt(params.page) > 0) {
    query.offset = params.page * query.limit - 1;
  }
  return {
    type: "QUERY_COMPANY_USERS",
    payload: APIUtils.get(`/companies/${companyId}/users?${qs.stringify(query)}`),
  };
};

export const getCompanyUser = (companyId, userId) => ({
  type: 'GET_COMPANY_USER',
  payload: APIUtils.get(`/companies/${companyId}/users/${userId}`)
})

export const updateCompanyUser = (companyId, user) => ({
  type: 'UPDATE_COMPANY_USER',
  payload: APIUtils.put(`/companies/${companyId}/users/${user.id}`, user)
})

export const deleteCompanyUser = (companyId, userId) => ({
  type: 'DELETE_COMPANY_USER',
  payload: APIUtils.del(`/companies/${companyId}/users/${userId}`)
})

export const updateMassEditUsers = (companyId, massEdit) => ({
  type: 'UPDATE_COMPANY_MASSEDIT_USER',
  payload: APIUtils.post(`/companies/${companyId}/users/massedit`, massEdit)
})

export const getUserAuthorizationGroups = (companyId, userId) => ({
  type: 'GET_USER_AUTHORIZATION_GROUPS',
  payload: APIUtils.get(`/companies/${companyId}/groups?userId=${userId}`)
})

export const getCompanyUserTags = (companyId, userId) => ({
  type: 'GET_COMPANY_USER_TAGS',
  payload: APIUtils.get(`/companies/${companyId}/users/${userId}/tags`)
})

export const addCompanyUserTags = (companyId, userId, tag) => {
  return APIUtils.post(`/companies/${companyId}/users/${userId}/tags`, tag)
}

export const removeCompanyUserTags = (companyId, userId, tagId) => {
  return APIUtils.del(`/companies/${companyId}/users/${userId}/tags/${tagId}`)
}

// Company User Permissions

export const getCompanyUserPermissions = (companyId, userId, isNotCurrentUser) => ({
  type: isNotCurrentUser?'GET_COMPANY_USER_PERMISSIONS':'GET_COMPANY_CURRENT_USER_PERMISSIONS',
  payload: APIUtils.get(`/companies/${companyId}/users/${userId}/permissions`)
})

export const updateCompanyUserPermissions = (companyId, userId, permission) => {
  if(permission.CompanyCreate){
    delete permission.CompanyCreate
  }

  return {
    type: 'UPDATE_COMPANY_USER_PERMISSIONS',
    payload: APIUtils.put(`/companies/${companyId}/users/${userId}/permissions`, permission)
  }

}

// Company User Permissions

export const queryCompanyStarObjects = (companyId, userId) => ({
  type: 'QUERY_COMPANY_STAR_OBJECTS',
  payload: APIUtils.get(`/companies/${companyId}/users/${userId}/starred?limit=20`)
})

export const queryCompanyUserInvites = (companyId, params = {}) => {
  let { page, ...query } = params;
  query.limit = LIMIT + 1;
  query.offset = 0;
  if (parseInt(params.page) > 0) {
    query.offset = params.page * query.limit - 1;
  }
  return {
    type: "QUERY_COMPANY_USER_INVITES",
    payload: APIUtils.get(`/companies/${companyId}/users/invited?${qs.stringify(query)}`),
  };
};

export const removeUserInvited = (companyId, params) => ({
  type: 'DELETE_COMPANY_USER_INVITE',
  payload: APIUtils.del(`/companies/${companyId}/users/delete-invited?${qs.stringify(params)}`)
})