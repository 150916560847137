import {CHANGE_LANGUAGE} from "../actions/root";

const Root = (state, action) => {
  switch (action.type) {
    case "LOGIN_FAILURE":
    case "CURRENT_USER_PENDING":
      return {
        ...state,
        currentUser: null
      };
    case "CURRENT_USER_FULFILLED":
      return {
        ...state,
        currentUser: action.payload.body
      };
    case "QUERY_ALL_COMPANIES_PENDING":
      return {
        ...state,
        allCompanies: []
      };
    case "QUERY_ALL_COMPANIES_FULFILLED":
      return {
        ...state,
        allCompanies: action.payload.body
      };

    case "QUERY_NOTICES_FULFILLED":
      return {
        ...state,
        notices: action.payload.body
      };
    case CHANGE_LANGUAGE:
      return {
        ...state,
        langKey: action.langKey
      };

    default:
      return state || {};
  }
};

export default Root;
