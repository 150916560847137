import React from 'react';
import T from "i18n-react";
import uuidV4 from "../utils/uuid";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { goBack, push } from "connected-react-router";
import { Modal, ModalBody } from "reactstrap";
import Notifications from "react-notification-system-redux";
import Flatpickr from "react-flatpickr";
import classNames from "classnames";
import DropdownOutside from "./DropdownOutside";
import UserAvatar from "./UserAvatar";
import APIUtils from "../utils/APIUtils";
import moment from "moment";
import UserPopover from "./UserPopover";
import QueryUser from "./QueryUser";
import update from "immutability-helper";
import se from "../utils/flatpickrLang";

const mapStateToProps = ({
  
}) => {
  return {
    
  };
};

const mapDispatchToProps = () => dispatch => ({
  dispatch,
  goBack: () => dispatch(goBack())
});

export class ModalMassEdit extends React.Component {
  state = {
    companyId: "",
    toggleModal: false,
    toggleForm: false,
    notice: {
      title: "",
      description: "",
    },
    massEdit: {
      ids: [],
      enddate: null,
      noticePeriod: null,
      reminderAt: null,
      assignees: null,
      members: []
    },
    completed: false,
    saved: false
  }

  componentWillMount() {
    if (this.props.massEdit) {
      this.setState({
          massEdit: this.props.massEdit
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.massEdit !== this.props.massEdit) {
      this.setState({
          massEdit: nextProps.massEdit
      });
    }
  }

	componentDidMount() {
    let localSelectedCompany = window.localStorage.selectedCompanyObj ? JSON.parse(window.localStorage.selectedCompanyObj) : null;
    this.setState({
      companyId: localSelectedCompany.id
    })
  }
  componentDidUpdate(prevProps) {
    if(this.props.massEdit !== prevProps.massEdit){
      this.setState({
        massEdit: this.props.massEdit
      });
    }
  }

  removeMember = m => {
    let massEdit = this.state.massEdit;
    if (m) {
      massEdit.members.splice(massEdit.members.indexOf(m), 1);
      massEdit.assignees.splice(massEdit.assignees.indexOf(m.id), 1);
    }
    this.setState(
      {
        massEdit: massEdit
      }
    );
  }

  checkDueAt = notice => {
    if (notice.enddate <= moment(notice.reminderAt).format("YYYY-MM-DD")) {
      notice.reminderAt = new Date(moment(notice.enddate).subtract(1, "month"));
      notice.noticePeriod = "1 month";
    }
    this.setState({
      notice: notice,
      hash: uuidV4()
    });
  }

  saveMassEdit = (massEdit) => {
    return APIUtils.put(
        "/companies/" + this.props.companyId + "/notices/mass-edit",
        massEdit
    ).then(
      resp => {
        this.props.dispatch(
          Notifications.success({
            title: "",
            message: T.translate("task.update-success"),
            position: "tc",
            autoDismiss: 5
          })
        );
        window.location.reload();
      },
      () => {
        this.props.dispatch(
          Notifications.error({
            title: "",
            message: T.translate("task.update-failure"),
            position: "tc",
            autoDismiss: 5
          })
        );
      }
    );
  }

  setCompleted = notice => {
    notice.status = true;
    this.saveMassEdit(notice).then(() => {
      window.location.reload();
    });
  }

  save = () => {
    this.saveMassEdit(this.state.massEdit).then(() => {
      
    });
  }

	render () {
    let {completed,notice} = this.state;
		return (
      <Modal
        ref={el => (this.componentRef = el)}
        isOpen={!this.state.toggleModal}
        toggle={() => {}}
        className={"notice-detail__wrapper"}
      >
        <ModalBody>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    this.save();
                    this.setState({
                        toggleForm: false
                    });
                }}
            >
              <div>
                <header className="notice-detail__header">
                    <h2>
                        <span className="reminder-detail__task-number">
                          <strong>{T.translate("notices.massedit-reminder")}</strong>
                        </span>
                    </h2>
                    <div className="publication-edit__wrap reminder-detail__edit-btn print-hide cf">
                        <button
                            type="button"
                            onClick={() => {
                              this.setCompleted(this.state.massEdit)
                            }}
                            className={"button"}
                        >
                          {T.translate("task.completed")}
                        </button>

                        <a
                            onClick={() => {
                              this.props.toggle();
                            }}
                            className="button reminder-detail__btn--negative"
                        >
                            <i className="fa fa-times" />
                            <span>{T.translate("close")}</span>
                        </a>
                    </div>
                </header>

                <div className="cf">
                    <div className="reminder-detail__column column-right">
                      <div className="row">
                        <label> {T.translate("notices.document-end-date")} </label>
                        <Flatpickr
                            disabled={this.state.toggleForm? "" : "disabled"}
                            onChange={v => {
                                this.setState(
                                    {
                                        massEdit: update(
                                            this.state.massEdit,
                                            {
                                                enddate: {
                                                    $set: new Date(
                                                        v
                                                    )
                                                }
                                            }
                                        )
                                    }, () => {
                                          this.setState({
                                            massEdit: update(
                                              this.state.massEdit,
                                              {
                                                  noticePeriod: { $set: "1 month" },
                                                  reminderAt: { $set: new Date(moment(this.state.massEdit.enddate).subtract(1, "month"))}
                                              })
                                          })
                                        }   
                                )
                            }}
                            key={`enable-time-${
                                this.state.hash
                            }`}
                            options={{
                                locale: se
                            }}
                        /> 
                      </div>
                      <div className="row">
                        <label>
                          {T.translate(
                              "notices.reminder-date"
                          )}
                        </label>
                        <Flatpickr
                            disabled={this.state.toggleForm? "" : "disabled"}
                            onChange={v => {
                                this.setState(
                                    {
                                        massEdit: update(
                                            this.state.massEdit,
                                            {
                                                reminderAt: {
                                                    $set: new Date(
                                                        v
                                                    )
                                                }
                                            }
                                        )
                                    },
                                    () => {
                                        this.checkDueAt(this.state.notice);
                                    }
                                );
                            }}
                            value={
                              this.state.massEdit.reminderAt ? moment(this.state.massEdit.reminderAt).format("YYYY-MM-DD") : null
                            }
                            key={`enable-time-${
                                this.state.hash
                            }`}
                            options={{
                                locale: se
                            }}
                        />     
                      </div>
                      <div className="row">
                          <h4 className="text-height">
                              {T.translate("task.members")}:
                          </h4>
                          <ul className="cf">
                            {this.state.massEdit.members ? this.state.massEdit.members.map(
                                v => (
                                    <li key={v.id}>
                                        <UserPopover                                       
                                            onDelete={() => {
                                                this.removeMember(v);
                                            }}
                                            user={v} 
                                        />
                                    </li>
                                )
                            ) : null}
                          </ul>
                          <QueryUser
                              companyId={
                                  this.props.companyId
                              }
                              disabled={(this.state.toggleForm ? "" : "disabled")}
                              onAfterSelect={v => {
                                  let members = this.state.massEdit.members;
                                  let assignees = this.state.massEdit.assignees;
                                  if(assignees == null) {
                                    assignees = [];
                                  }
                                  members.push(v);
                                  assignees.push(v.id);
                                  this.setState(
                                      {
                                          massEdit: update(
                                              this.state.massEdit,
                                              {
                                                  members: {
                                                      $set: members
                                                  },
                                                  assignees: {
                                                      $set: assignees
                                                  }
                                              }
                                          )
                                      }, () => {
                                        //this.save();
                                      }
                                  );
                              }}
                              items={
                                  this.state.massEdit.members
                              }
                          />
                        </div>
                      </div>
                    </div>
                  <div className="separator reminder-detail__bottom cf print-hide">
                    <div className="line-component line-component--omega">
                      {this.state.toggleForm ? (
                          <button
                              className="button button--positive"
                              type="submit"
                          >
                              <i className="fa fa-floppy-o" />
                              {T.translate("save")}
                          </button>
                      ) : null}

                      {this.state.toggleForm ? 
                          <button
                              type="button"
                              className="button button--negative"
                              onClick={() => {
                                  this.setState({
                                      toggleForm: false
                                  });
                              }}
                          >
                              {T.translate("cancel")}
                          </button> : null }
                      {!this.state.notice.status && !this.state.toggleForm ? (
                          <button
                              type="button"
                              className="button"
                              onClick={() => {
                                  this.setState({
                                      toggleForm: !this.state
                                          .toggleForm
                                  });
                              }}
                          >
                              <i className="fa fa-edit" />
                              <span>{T.translate("edit")}</span>
                          </button>
                          ) : null}
                      <a
                        onClick={() => {
                          this.props.toggle();
                        }}
                        className="button reminder-detail__btn--negative"
                      >
                        <i className="fa fa-times" />
                        <span>{T.translate("close")}</span>
                      </a>
                </div>
              </div>
            </div>
      			
          </form>
        </ModalBody>
      </Modal>
		)
	}
}

ModalMassEdit.propTypes = {
    toggle: PropTypes.func,
    massEdit: PropTypes.object
};

export default connect(mapStateToProps,mapDispatchToProps)(ModalMassEdit);