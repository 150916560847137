import {
    QUERY_COMPANY_FLOW_VERIFIES,
    GET_COMPANY_FLOW_VERIFY
} from "../../../actions/company/flow-verifies/CompanyFlowVerifyActions";

const CompanyFlowVerifies = (
    state = {
        flowVerifies: [],
        flowVerify: null
    },
    action
) => {
    switch (action.type) {

        case QUERY_COMPANY_FLOW_VERIFIES + "_FULFILLED":
            return {
                ...state,
                flowVerifies: action.payload.body
            }

        case GET_COMPANY_FLOW_VERIFY + "_FULFILLED":
            return {
                ...state,
                flowVerify: action.payload.body
            }

        default:
            return state;
    }
};

export default CompanyFlowVerifies;
