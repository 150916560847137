import {
  QUERY_COMPANY_CUSTOMER_PORTALS
} from "../../../actions/company/customer-portals/CompanyCustomerPortalActions";

const CompanyCustomerPortal = (state = { customerPortals: [] }, action) => {
  switch (action.type) {
    case QUERY_COMPANY_CUSTOMER_PORTALS + "_FULFILLED":
      return {
        ...state,
        customerPortals: action.payload.body
      };

    default:
      return state;
  }
};

export default CompanyCustomerPortal;
