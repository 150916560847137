import {
    QUERY_USER_LOGIN_LOGS,
    QUERY_USER_LOGIN_APPS
  } from "../../actions/login-logs/UserLoginLogActions";
  import { toMap } from "../../utils/toMap";
  
  const UserLoginLog = (state = { loginLogs: [], loginApps: [] }, action) => {
    switch (action.type) {
        case QUERY_USER_LOGIN_LOGS + "_FULFILLED":
            return {
                ...state,
                loginLogs: action.payload.body
            };
        case QUERY_USER_LOGIN_APPS + "_FULFILLED":
            return {
                ...state,
                loginApps: toMap(action.payload.body)
            };
  
        default:
            return state;
    }
  };
  
  export default UserLoginLog;
  