import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_INVESTIGATION_TEMPLATES = "QUERY_COMPANY_INVESTIGATION_TEMPLATES";
export const CREATE_COMPANY_INVESTIGATION_TEMPLATE = "CREATE_COMPANY_INVESTIGATION_TEMPLATE";
export const DELETE_COMPANY_INVESTIGATION_TEMPLATE = "DELETE_COMPANY_INVESTIGATION_TEMPLATE";
export const UPDATE_COMPANY_INVESTIGATION_TEMPLATE = "UPDATE_COMPANY_INVESTIGATION_TEMPLATE";

export const queryInvestigationTemplates = (companyId, params = {}) => {
  let queryStr = qs.stringify(params, { addQueryPrefix: true });
  return {
    type: QUERY_COMPANY_INVESTIGATION_TEMPLATES,
    payload: APIUtils.get(`/companies/${companyId}/investigation-templates${queryStr}`)
  };
};
export const createInvestigationTemplate = (companyId, investigationTemplate) => ({
  type: CREATE_COMPANY_INVESTIGATION_TEMPLATE,
  payload: APIUtils.post(`/companies/${companyId}/investigation-templates`, investigationTemplate)
});
export const updateInvestigationTemplate = (companyId, investigationTemplate) => ({
  type: UPDATE_COMPANY_INVESTIGATION_TEMPLATE,
  payload: APIUtils.put(`/companies/${companyId}/investigation-templates/${investigationTemplate?.id}`, investigationTemplate)
});

export const deleteInvestigationTemplate = (companyId, investigationTemplateId) => ({
  type: DELETE_COMPANY_INVESTIGATION_TEMPLATE,
  payload: APIUtils.del(`/companies/${companyId}/investigation-templates/${investigationTemplateId}`)
});
