import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_CUSTOMERS = "QUERY_COMPANY_CUSTOMERS";

export const queryCompanyCustomers = (companyId, params = {}) => {
  let queryStr = qs.stringify(params, { addQueryPrefix: true });
  return {
    type: QUERY_COMPANY_CUSTOMERS,
    payload: APIUtils.get(`/companies/${companyId}/customers${queryStr}`)
  };
};
