import {
    QUERY_COMPANY_GROUP_CATEGORIES
  } from "../../../actions/company/groups/CompanyGroupCategoryActions";
  
  const CompanyGroupCategory = (state = { 
    groupCategories: []
  }, action) => {
    switch (action.type) {
      case QUERY_COMPANY_GROUP_CATEGORIES + "_FULFILLED":
        return {
          ...state,
          groupCategories: action.payload.body
        };
  
      default:
        return state;
    }
  };
  
  export default CompanyGroupCategory;
  