import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import T from "i18n-react";
import APIUtils from "../utils/APIUtils";
import { APP_URL, API_BASE } from "../config";
import { CSSTransition } from "react-transition-group";
import { logout } from "../actions/auth";
import UserAvatar from "./UserAvatar";
import DropdownOutside from "./DropdownOutside";
import CompanyLogo from "./CompanyLogo";
import TextCarousel from "./TextCarousel";
import { push } from "connected-react-router";
import { queryNotices } from "../actions/root";
import { getCompany } from "../actions/company/CompanyActions";
import { getCompanyUserPermissions } from "../actions/company/user/user";
import { queryMessages } from "../actions/company/messages/CompanyMessageActions";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { queryCompanyCustomers } from "../actions/company/customers/CompanyCustomerActions";
import Notification from "./Notification";
import config from "../config";

const mapStateToProps = ({
  Root: { allCompanies, currentUser, notices },
  Company: { company },
  SelectCompany: { selectedCompany },
  CompanyUserInfo: { userPermissions, starredObjects },
  CompanyMessage: { messages },
  CompanyCustomer: { customers },
  webSocket,
}) => {
  return {
    allCompanies,
    currentUser,
    notices,
    company,
    selectedCompany,
    userPermissions,
    starredObjects,
    messages,
    customers,
    webSocket,
  };
};

const mapDispatchToProps = () => (dispatch) => ({
  dispatch,
  doLogout: () => dispatch(logout()),
  queryNotices: () => dispatch(queryNotices()),
  getCompany: (companyId) => dispatch(getCompany(companyId)),
  getCompanyUserPermissions: (companyId, userId) =>
    dispatch(getCompanyUserPermissions(companyId, userId)),
  queryCompanyCustomers: (companyId, params) =>
    dispatch(queryCompanyCustomers(companyId, params)),
  queryMessages: (companyId, params) =>
    dispatch(queryMessages(companyId, params)),
});

export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userMenuOpen: false,
      toggleMenuLeft: false,
      toggleCompanyMenu: false,
      filterCompany: "",
      allCompanies: [],
      userId: null
    };

    this.clickOutside = this.clickOutside.bind(this);
    this.toggleUserMenuOpen = this.toggleUserMenuOpen.bind(this);
  }

  selectCompany = (company) => {
    if (window.localStorage.userId) {
      this.props.getCompanyUserPermissions(
        company.id,
        window.localStorage.userId
      );
    }
    this.props.queryMessages(company.id, { limit: 7 });
    this.props.dispatch({
      type: "SELECTED_COMPANY",
      selectedCompany: company,
    });
    APIUtils.get(API_BASE+"/companies/"+company.id+"/users/"+window.localStorage.userId).then((resp)=>{
        if(resp.body.features.denyLoginOnWeb){
            this.props.dispatch(push("/403"));
        } 
    });
    
    window.localStorage.selectedCompanyObj = JSON.stringify(company);
    this.props.dispatch(push("/"));
  };

  onChange = (event) => {
    this.setState({
      filterCompany: event.target.value ? event.target.value : "",
    });
  };

  componentWillMount() {
    this.props.queryNotices();
    let toggleCompanyMenu = false;
    if (this.props.location.pathname.indexOf("/calendar/") > -1) {
      let companyId = this.props.location.pathname.split("/calendar/")[1];
      if (companyId && window.localStorage.userId) {
        this.props.getCompany(companyId);
        this.props.getCompanyUserPermissions(
          companyId,
          window.localStorage.userId
        );
        toggleCompanyMenu = true;
      } else {
        toggleCompanyMenu = false;
      }
    } else {
      toggleCompanyMenu = false;
    }
    this.setState({
      toggleCompanyMenu: toggleCompanyMenu,
    });
    if(this.props.location.pathname.indexOf("/settings/users/")>-1){
      let userId = this.props.location.pathname.split('/settings/users/')[1].split("/")[0]
      this.setState({
        userId
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location !== nextProps.location) {
      let toggleCompanyMenu = false;
      if (nextProps.location.pathname.indexOf("/calendar/") > -1) {
        let companyId = nextProps.location.pathname.split("/calendar/")[1];
        if (companyId && window.localStorage.userId) {
          this.props.getCompany(companyId);
          this.props.getCompanyUserPermissions(
            companyId,
            window.localStorage.userId
          );
          toggleCompanyMenu = true;
        } else {
          toggleCompanyMenu = false;
        }
      } else {
        toggleCompanyMenu = false;
      }
      this.setState({
        toggleCompanyMenu: toggleCompanyMenu,
      });
      if(this.props.location.pathname !== nextProps.location.pathname){
        if(nextProps.location.pathname.indexOf("/settings/users/")>-1){
          let userId = nextProps.location.pathname.split('/settings/users/')[1].split("/")[0]
          this.setState({
            userId
          })
        }else{
          this.setState({
            userId: null
          })
        }
      }
    }
    if (this.props.selectedCompany !== nextProps.selectedCompany) {
      if (nextProps.selectedCompany.enabledFeatures.customers) {
        this.props.queryCompanyCustomers(nextProps.selectedCompany.id, {
          all: true,
          has_portal: true,
        });
      } else {
        this.props.dispatch({
          type: "USER_HAVE_NO_CUSTOMER_FEATURE",
        });
      }
    }
    if (this.props.allCompanies !== nextProps.allCompanies) {
      if (nextProps.allCompanies && nextProps.allCompanies[0]) {
        let selectedCompany = nextProps.allCompanies[0];
        let localSelectedCompany = window.localStorage.selectedCompanyObj
          ? JSON.parse(window.localStorage.selectedCompanyObj)
          : null;
        if (
          localSelectedCompany &&
          nextProps.allCompanies.filter((company) => {
            return company.id === localSelectedCompany.id;
          })[0] &&
          window.localStorage.userId === window.localStorage.oldUser
        ) {
          selectedCompany = localSelectedCompany;
        } else {
          window.localStorage.selectedCompanyObj = JSON.stringify(
            selectedCompany
          );
          window.localStorage.oldUser = window.localStorage.userId;
        }
        if (window.localStorage.userId) {
          this.props.getCompanyUserPermissions(
            selectedCompany.id,
            window.localStorage.userId
          );
        }
        this.props.queryMessages(selectedCompany.id, { limit: 7 });
        this.props.dispatch({
          type: "SELECTED_COMPANY",
          selectedCompany: selectedCompany,
        });
      }

      this.setState({
        allCompanies: nextProps.allCompanies,
      });
    }
  }

  doLogout = () => {
    this.props.doLogout();
    if (this.props.webSocket.updateSocket) {
      this.props.webSocket.updateSocket.close();
      this.props.dispatch({
        type: "UPDATE_WEBSOCKET",
        updateSocket: this.props.webSocket.updateSocket,
      });
    }
  };

  clickOutside = () => {
    this.setState({ userMenuOpen: false });
  };

  toggleUserMenuOpen = () => {
    this.setState({
      userMenuOpen: !this.state.userMenuOpen,
    });
  };

  clickOutsideMenuLeft = () => {
    this.setState({ toggleMenuLeft: false });
  };

  toggleMenuLeftOpen = () => {
    this.setState({
      toggleMenuLeft: !this.state.toggleMenuLeft,
    });
  };



  render() {
    let { company, userPermissions, selectedCompany } = this.props;
    return (
      <div>
        {this.props.currentUser ? (
          <div>
            <header className="header__wrap promote-layer cf">
              <div className="header__icons">
                {this.props.currentUser ? (
                  <ul>

                    <li className="header__icon">
                      <DropdownOutside
                        onClickOutside={this.clickOutsideMenuLeft}
                      >
                        <button
                          onClick={this.toggleMenuLeftOpen}
                          className="button icon-only stacking"
                          type="button"
                        >
                          <span className="fa-stack">
                            <i className="fa fa-circle fa-stack-2x" />
                            <i className="fa fa-bars" />
                          </span>
                        </button>
                        <CSSTransition
                          in={this.state.toggleMenuLeft}
                          timeout={200}
                          classNames="sidebar"
                          unmountOnExit
                        >
                          {(state) => (
                            <aside className="navdrawer__menu-left">
                              <button
                                type="button"
                                className="right-menu__close icon-only"
                                onClick={this.clickOutsideMenuLeft}
                              >
                                <i className="fa fa-close"></i>
                              </button>

                              <ul>
                                <li className="navdrawer__item-left">
                                  <Link
                                    to={`/login`}
                                    onClick={this.clickOutsideMenuLeft}
                                    title={T.translate("home")}
                                  >
                                    {T.translate("home")}
                                  </Link>
                                </li>
                                {this.props.selectedCompany &&
                                this.props.selectedCompany.enabledFeatures
                                  .documentReminder ? (
                                  <li className="navdrawer__item-left hide-above-small">
                                    <Link
                                      to={`/list/reminder`}
                                      onClick={this.clickOutsideMenuLeft}
                                      title={T.translate("task.type.reminder")}
                                    >
                                      {T.translate("task.type.reminder")}
                                    </Link>
                                  </li>
                                ) : null}
                                {this.props.selectedCompany &&
                                userPermissions?.CertifyDocument && 
                                this.props.selectedCompany.enabledFeatures
                                  .investigation &&
                                this.props.selectedCompany.enabledFeatures.publicationTypes.filter(
                                  (c) => {
                                    return (
                                      c
                                        .toLowerCase()
                                        .indexOf(
                                          "INVESTIGATION".toLowerCase()
                                        ) > -1
                                    );
                                  }
                                )[0] ? (
                                  <li className="navdrawer__item-left">
                                    <Link
                                      to={`/list/investigation`}
                                      onClick={this.clickOutsideMenuLeft}
                                    >
                                      {T.translate(
                                        "publication.list-investigations"
                                      )}
                                    </Link>
                                  </li>
                                ) : null}
                                {userPermissions?.CertifyDocument && this.props.selectedCompany &&
                                this.props.selectedCompany.enabledFeatures
                                  .esign ? (
                                  <li className="navdrawer__item-left">
                                    <Link
                                      to={`/list/esign`}
                                      onClick={this.clickOutsideMenuLeft}
                                    >
                                      {T.translate(
                                        "publication.list-esign-emails"
                                      )}
                                    </Link>
                                  </li>
                                ) : null}
                                {userPermissions &&
                                userPermissions.CompanyActivities &&
                                this.props.selectedCompany
                                  ? [
                                      <li className="navdrawer__item-left" key={0}>
                                        <a
                                          href={`${config.APP_URL}/companies/${this.props.selectedCompany.id}/watched`}
                                          target="_blank"
                                        >
                                          {T.translate(
                                            "publication.list-watch-documents"
                                          )}
                                        </a>
                                      </li>,
                                      <li className="navdrawer__item-left" key={1}>
                                        <a
                                          href={`${config.APP_URL}/companies/${this.props.selectedCompany.id}/share-links`}
                                          target="_blank"
                                        >
                                          {T.translate(
                                            "publication.list-shared-links"
                                          )}
                                        </a>
                                      </li>,
                                      <li className="navdrawer__item-left" key={2}>
                                        <a
                                          href={`${config.APP_URL}/companies/${this.props.selectedCompany.id}/comments`}
                                          target="_blank"
                                        >
                                          {T.translate(
                                            "publication.list-all-comments"
                                          )}
                                        </a>
                                      </li>,
                                      this.props.selectedCompany &&
                                      this.props.selectedCompany.enabledFeatures
                                        .documentReminder ? (
                                        <li className="navdrawer__item-left" key={3}>
                                          <Link
                                            to={`/list/reminder`}
                                            onClick={this.clickOutsideMenuLeft}
                                          >
                                            {T.translate(
                                              "publication.reminder-list"
                                            )}
                                          </Link>
                                        </li>
                                      ) : null,
                                    ]
                                  : null}
                                {userPermissions.CompanyUnits && (
                                  <li className="navdrawer__item-left" key={4}>
                                    <Link
                                      to={`/list/units`}
                                      onClick={this.clickOutsideMenuLeft}
                                    >
                                      {T.translate("units.title")}
                                    </Link>
                                  </li>
                                )}
                                {userPermissions.CompanyUnits && selectedCompany && selectedCompany.enabledFeatures && selectedCompany.enabledFeatures.skills && (
                                  <li className="navdrawer__item-left" key={5}>
                                    <Link
                                      to={`/list/units-kunskap`}
                                      onClick={this.clickOutsideMenuLeft}
                                    >
                                      {T.translate("units.knowledge")}
                                    </Link>
                                  </li>
                                )}

                                {selectedCompany && selectedCompany.enabledFeatures && selectedCompany.enabledFeatures.skills && (
                                  <li className="navdrawer__item-left" key={6}>
                                    <Link
                                      to={`/list/skills-list`}
                                      onClick={this.clickOutsideMenuLeft}
                                    >
                                      {T.translate("skills.skills-list")}
                                    </Link>
                                  </li>
                                )}
                              </ul>
                            </aside>
                          )}
                        </CSSTransition>
                      </DropdownOutside>
                    </li>
                  </ul>
                ) : null}
              </div>
              <div className="header__logo cf">
                <div className="line-component line-component--padded">
                  <Link to={`/`}>
                    {this.props.companyId ? (
                      <CompanyLogo
                        showDefaultLogo="true"
                        companyId={this.props.companyId}
                      />
                    ) : (
                      <img
                        src="images/logos/logo-horizontal-rbg-white.png"
                        alt="Dokiv logotype"
                      />
                    )}
                  </Link>
                </div>

                <div className="line-component line-component--padded hide-below-tiny">
                  <Dropdown
                    className={
                      "select__wrap header__icon " +
                      (this.state.toggleCompany ? "open" : "")
                    }
                    isOpen={this.state.toggleCompany}
                    toggle={() => {
                      this.setState({
                        toggleCompany: !this.state.toggleCompany,
                      });
                    }}
                  >
                    <DropdownToggle className="select__button button">
                      {this.props.selectedCompany
                        ? this.props.selectedCompany.name
                        : ""}
                      <i
                        className={
                          "fa " +
                          (this.state.toggleCompany
                            ? "fa-caret-up"
                            : "fa-caret-down")
                        }
                      />
                    </DropdownToggle>
                    <DropdownMenu>
                      <ul className="select__list dropdown-company">
                        <li className="select__item">
                          <input
                            value={this.state.filterCompany}
                            onChange={this.onChange}
                            placeholder={T.translate("company.search")}
                          />
                        </li>
                        {this.state.allCompanies &&
                        this.state.allCompanies.length
                          ? this.state.allCompanies
                              .filter((company) => {
                                return (
                                  company.name
                                    .toLowerCase()
                                    .indexOf(
                                      this.state.filterCompany.toLowerCase()
                                    ) > -1
                                );
                              })
                              .map((v, i) => (
                                <li
                                  key={v.id}
                                  className={
                                    "text-height select__item " +
                                    (this.props.selectedCompany &&
                                    this.props.selectedCompany.id === v.id
                                      ? " active"
                                      : "")
                                  }
                                  onClick={() => {
                                    this.selectCompany(v);
                                    this.setState({
                                      toggleCompany: !this.state.toggleCompany,
                                      filterCompany: "",
                                    });
                                  }}
                                >
                                  {v.name}
                                </li>
                              ))
                          : null}
                      </ul>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <nav className="header__user-menu">
                {this.props.currentUser ? (
                  <ul className="cf">
                    <Notification companyId={this.props.companyId} />

                    <li className="header__icon header__user-dropdown">
                      <DropdownOutside
                        className="line-component"
                        onClickOutside={this.clickOutside}
                      >
                        <button
                          className={
                            "button dropdown__toggle " +
                            (this.state.userMenuOpen ? "active" : null)
                          }
                          onClick={this.toggleUserMenuOpen}
                          type="button"
                        >
                          <UserAvatar
                            moreClasses="header__user-avatar"
                            user={this.props.currentUser}
                          />
                          <span className="header__user-name">
                            <span className="hide-below-tiny">
                              {this.props.currentUser.name}
                            </span>
                          </span>
                          <i className="fa fa-chevron-down" />
                        </button>

                        <CSSTransition
                          in={this.state.userMenuOpen}
                          timeout={200}
                          classNames="sidebar"
                          unmountOnExit
                        >
                          {(state) => (
                            <aside className="right-menu__wrap">
                              <div className="right-menu__body">
                                <h3 className="right-menu__header">
                                  {T.translate("account")}
                                </h3>
                                <h4>{this.props.currentUser.name}</h4>
                                <ul className="dropdown__list">
                                  <li>
                                    <Link
                                      onClick={this.clickOutside}
                                      to={`/profile/${this.props.currentUser.id}/detail`}
                                    >
                                      <i className="fa fa-user" />
                                      {T.translate("profile")}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to={`/`} onClick={this.clickOutside}>
                                      <i className="fa fa-building" />
                                      {T.translate("company.change")}
                                    </Link>
                                  </li>
                                </ul>
                                <ul className="dropdown__list">
                                  <li>
                                    <h4 className="right-menu__sub-header">
                                      {T.translate("system")}
                                    </h4>
                                  </li>
                                  <li>
                                    <Link
                                      onClick={this.clickOutside}
                                      to={`/settings/information`}
                                    >
                                      <i className="fa fa-cog" />
                                      {T.translate("settings.title")}
                                    </Link>
                                  </li>
                                  <li onClick={this.doLogout}>
                                    <a className="logout">
                                      <i className="fa fa-sign-out" />
                                      {T.translate("logout")}
                                    </a>
                                  </li>
                                </ul>
                                <div className="row">
                                  <h3 className="right-menu__header">
                                    {T.translate("company.select")}
                                  </h3>
                                  <div className="select-company__search">
                                    <input
                                      type="search"
                                      name="filterCompany"
                                      value={this.state.filterCompany}
                                      onChange={this.onChange}
                                      id="searchCompanies"
                                      placeholder={T.translate(
                                        "company.search"
                                      )}
                                    />
                                  </div>
                                  <ul className="right-menu__companies">
                                    {this.state.allCompanies &&
                                      this.state.allCompanies.map(
                                        (company, i) => (
                                          <li key={company.id}>
                                            {company.userType === "DEFAULT" ? (
                                              <a
                                                target="_self"
                                                href={`${config.APP_URL}/companies/${company.id}/`}
                                              >
                                                {company.name}
                                              </a>
                                            ) : (
                                              <a
                                                target="_self"
                                                href={`${config.APP_URL}/companies/${company.id}/guest/publications`}
                                              >
                                                {company.name}
                                              </a>
                                            )}
                                          </li>
                                        )
                                      )}
                                  </ul>
                                </div>
                              </div>
                              <button
                                type="button"
                                className="right-menu__close icon-only"
                                onClick={this.toggleUserMenuOpen}
                              >
                                <i className="fa fa-close" />
                              </button>
                            </aside>
                          )}
                        </CSSTransition>
                      </DropdownOutside>
                    </li>
                  </ul>
                ) : null}
              </nav>
            </header>
            {this.props.notices ? (
              <TextCarousel notices={this.props.notices} />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
