import APIUtils from "../utils/APIUtils";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export const currentUser = userId => ({
  type: "CURRENT_USER",
  payload: APIUtils.get("/users/" + userId)
});

export const queryAllCompanies = () => ({
  type: "QUERY_ALL_COMPANIES",
  payload: APIUtils.get("/companies?limit=10000")
});

export const queryNotices = () => ({
  type: "QUERY_NOTICES",
  payload: APIUtils.get("/notices")
});


export const changeLanguage = langKey => ({
	type: CHANGE_LANGUAGE,
	langKey
})