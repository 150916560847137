import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_CUSTOMER_PORTALS = "QUERY_COMPANY_CUSTOMER_PORTALS";
export const UPDATE_PUBLICATION = "UPDATE_PUBLICATION";

export const queryCustomerPortals = (companyId, params = {}) => {
  let queryStr = qs.stringify(params, { addQueryPrefix: true });
  return {
    type: QUERY_COMPANY_CUSTOMER_PORTALS,
    payload: APIUtils.get(`/companies/${companyId}/publications${queryStr}`)
  };
};

export const updatePublication = (companyId, publication) => ({
  type: UPDATE_PUBLICATION,
  payload: APIUtils.put(`/companies/${companyId}/publications/${publication.id}`, publication),
});
