import {
  QUERY_TASKS,
  GET_TASK,
  QUERY_TASK_CATEGORIES
} from "../../../actions/company/tasks/CompanyTaskActions";

const Tasks = (state = { tasks: [], taskCategories: [] }, action) => {
  switch (action.type) {
    case "GET_MY_TASKS_FULFILLED": {
      return {
        ...state,
        tasks: action.payload.body
      };
    };

    case QUERY_TASKS + "_FULFILLED":
      return {
        ...state,
        tasks: action.payload.body.filter((task) => {
          return task.type === "EVENT" || task.type === "TODO"
        })
      };

    case GET_TASK + "_FULFILLED":
      return {
        ...state,
        task: action.payload.body
      };

    case QUERY_TASK_CATEGORIES + "_FULFILLED":
      return {
        ...state,
        taskCategories: action.payload.body
      };

    default:
      return state;
  }
};

export default Tasks;
