import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_FORM_GROUPS = "QUERY_COMPANY_FORM_GROUPS";
export const CREATE_NEW_FORM_APP_GROUP = "CREATE_NEW_FORM_APP_GROUP";
export const CREATE_NEW_FORM_APP_GROUP_USER = "CREATE_NEW_FORM_APP_GROUP_USER";

export const queryCompanyFormGroups = (companyId, params = {}) => {
  let queryStr = qs.stringify(params, { addQueryPrefix: true });
  return {
    type: QUERY_COMPANY_FORM_GROUPS,
    payload: APIUtils.get(`/companies/${companyId}/form-groups${queryStr}`)
  };
};

export const createFormAppGroup = (companyId, formAppGroup) => ({
	type: CREATE_NEW_FORM_APP_GROUP,
	payload: APIUtils.post(`/companies/${companyId}/form-groups`, formAppGroup),
});

export const createFormAppGroupUser = (companyId, groupId, user) => ({
	type: CREATE_NEW_FORM_APP_GROUP_USER,
	payload: APIUtils.post(`/companies/${companyId}/form-groups/${groupId}/users`, user),
});
