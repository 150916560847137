const CompanyUserInfo = (state = {permissions: null, userPermissions: null, starredObjects: [], users: [], hasMore: false, companyUserTags: []}, action) => {
  switch (action.type) {
    case "GET_COMPANY_USER_FULFILLED": {
      return {
        ...state,
        companyUser: action.payload.body
      };
    }
    case "QUERY_COMPANY_USERS_FULFILLED":
        return {
            ...state,
            users: action.payload.body.slice(0, 20),
            hasMore: action.payload.body.length > 20
        };

    case "GET_USER_AUTHORIZATION_GROUPS_FULFILLED": {
      return {
        ...state,
        userAuthorizationGroups: action.payload.body
      };
    }

    case "GET_COMPANY_USER_TAGS_FULFILLED": {
      return {
        ...state,
        companyUserTags: action.payload.body
      };
    }

    case "GET_COMPANY_USER_PERMISSIONS_FULFILLED": {
      return {
        ...state,
        permissions: action.payload.body
      };
    }

    case "GET_COMPANY_CURRENT_USER_PERMISSIONS_FULFILLED": {
      return {
        ...state,
        userPermissions: action.payload.body
      };
    }

    case "QUERY_COMPANY_STAR_OBJECTS_FULFILLED": {
      return {
        ...state,
        starredObjects: action.payload.body
      };
    }
    case "QUERY_COMPANY_USER_INVITES_FULFILLED":
      return {
          ...state,
          usersInvited: action.payload.body
      };

    default:
      return state || {};
  }
};

export default CompanyUserInfo;