import React from "react";
import UserAvatar from "./UserAvatar";
import { connect } from "react-redux";
import T from "i18n-react";
import APIUtils from "../utils/APIUtils";
import DropdownOutside from "../components/DropdownOutside";

const mapStateToProps = state => state;

export class QueryUser extends React.Component {
    constructor(props) {
        super(props);
        this.updateNonExistentUsers = this.updateNonExistentUsers.bind(this);

        this.state = {
            toggleSelect:
                this.props.disabled === "boolean" ? this.props.disabled : true,
            items: this.props.items,
            filterManager: "",
            nonExistentUsers: [],
            showFilterManagerDropdown: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.disabled !== this.state.toggleSelect) {
            this.setState({
                toggleSelect: nextProps.disabled
            });
        }
        if (nextProps.items !== this.props.items) {
            this.setState({
                items: nextProps.items
            });
        }
    }

    updateNonExistentUsers = filter => {
        let { items } = this.props;
        APIUtils.get(
            "/companies/" +
                this.props.companyId +
                "/users?q=" +
                filter +
                (!this.props.typeAll?"&type=DEFAULT":"") +
                "&limit=10"
        ).then(resp => {
            let users = resp.body;
            if (items) {
                if (!Array.isArray(items)) {
                    items = [items];
                }

                users = users.filter(function(item) {
                    return !items.some(function(u) {
                        return item.id === u.id;
                    });
                });
            }
            this.setState({
                nonExistentUsers: users
            });
        });
    };

    render() {
        let { items } = this.state;
        return (
            <div>
                {this.props.queryType === "manager"
                    ? <div>
                        <h4 className="text-height">
                            {T.translate("manager")}:
                        </h4>
                        <DropdownOutside
                            onClickOutside={() => {
                                this.setState({
                                    showFilterManagerDropdown: false
                                });
                            }}
                        >
                            <div
                                className={
                                    "select__wrap " +
                                    (this.state.showFilterManagerDropdown ? "open" : "")
                                }
                            >
                            {items && !this.state.toggleSelect
                                ? <i
                                    onClick={() => {
                                        this.setState({
                                            items: null
                                        });

                                        if (typeof this.props.onAfterSelect === "function") {
                                            this.props.onAfterSelect(null);
                                        }
                                    }}
                                    className="fa fa-times reminder-detail__remove-manager"
                                />
                            : null}
                            <div className="">
                                <input
                                    placeholder={T.translate("manager")}
                                    className="select__button"
                                    value={items ? items.name : this.state.filterManager}
                                    disabled={this.props.disabled}
                                    onChange={e => {
                                        this.setState(
                                            {
                                                filterManager: e.target.value
                                            },
                                            () => {
                                                this.updateNonExistentUsers(
                                                    this.state.filterManager
                                                );
                                            }
                                        );
                                    }}
                                    onFocus={()=>{
                                        this.setState({
                                            showFilterManagerDropdown: true
                                        });
                                    }}
                                    type="text"
                                />
                                {this.state.nonExistentUsers && this.state.showFilterManagerDropdown
                                  ? <ul className="select__list">
                                        {this.state.nonExistentUsers.map((v, i) =>
                                            <li
                                                key={i}
                                                className="select__item select__item--image"
                                                onClick={() => {
                                                    let { nonExistentUsers } = this.state;
                                                    nonExistentUsers.splice(
                                                        nonExistentUsers.indexOf(v),
                                                        1
                                                    );
                                                    this.setState({
                                                        items: v,
                                                        showFilterManagerDropdown: false,
                                                        filterManager: "",
                                                        nonExistentUsers: nonExistentUsers
                                                    });

                                                    if ( typeof this.props.onAfterSelect ===
                                                          "function"
                                                    ) {
                                                        this.props.onAfterSelect(v);
                                                    }
                                              }}
                                            >
                                                <UserAvatar user={v} />
                                                <span className="select__text">
                                                    {v.name}
                                                </span>
                                                <span
                                                    className={
                                                        "user-list__type " +
                                                        (v.type === "GUEST"
                                                            ? "user-list__type--guest"
                                                            : "user-list__type--default")
                                                    }
                                                />
                                            </li>
                                        )}
                                    </ul>
                                : null}
                            </div>
                        </div>
                      </DropdownOutside>
                    </div>
                    : <div className="row">
                        <DropdownOutside
                            onClickOutside={() => {
                                this.setState({
                                    showFilterManagerDropdown: false
                                });
                            }}
                          >
                            <div
                                className={
                                    "select__wrap " +
                                    (this.state.showFilterManagerDropdown ? "open" : "")
                                }
                            >
                                <input
                                    placeholder={this.props.placeholder?this.props.placeholder:T.translate("task.members-add")}
                                    className="select__button"
                                    value={this.state.filterManager}
                                    disabled={this.props.disabled}
                                    onFocus={() => {
                                      this.setState({
                                        showFilterManagerDropdown: true
                                      });
                                    }}
                                    onChange={e => {
                                        this.setState(
                                        {
                                            filterManager: e.target.value
                                        },
                                        () => {
                                          this.updateNonExistentUsers(this.state.filterManager);
                                        }
                                      );
                                    }}
                                    type="text"
                                />
                                {this.state.showFilterManagerDropdown
                                    ? <ul className="select__list">
                                        {this.state.nonExistentUsers.map((v, i) =>
                                            <li
                                                key={i}
                                                className="select__item select__item--image"
                                                onClick={() => {
                                                    let { nonExistentUsers } = this.state;
                                                    nonExistentUsers.splice(
                                                        nonExistentUsers.indexOf(v),
                                                        1
                                                    );
                                                    this.setState({
                                                        showFilterManagerDropdown: false,
                                                        filterManager: "",
                                                        nonExistentUsers: nonExistentUsers
                                                    });
                                                    if (
                                                        typeof this.props.onAfterSelect === "function"
                                                    ) {
                                                        this.props.onAfterSelect(v);
                                                    }
                                                }}
                                            >
                                                <UserAvatar user={v} title={v.name} />
                                                <span className="select__text">
                                                    {v.name}
                                                </span>
                                            </li>
                                        )}
                                    </ul>
                                : null}
                            </div>
                        </DropdownOutside>
                    </div>}
                </div>
            );
    }
}

export default connect(
    mapStateToProps
    // Implement map dispatch to props
)(QueryUser);