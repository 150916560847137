import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_MESSAGES = "QUERY_MESSAGES";

export const queryMessages = (companyId, params = {}) => {
  let queryStr = qs.stringify(Object.assign({}, params, {me: "true"}), { addQueryPrefix: true });
  return {
    type: QUERY_MESSAGES,
    payload: APIUtils.get(`/companies/${companyId}/posts${queryStr}`)
  };
};
