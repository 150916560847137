import React from "react";
import T from "i18n-react";
import { connect } from "react-redux";
import {
  queryNotifications,
  markAsRead,
  markAllRead
} from "../actions/notification";
import UserAvatar from "./UserAvatar";
import update from "immutability-helper";
import DropdownOutside from "./DropdownOutside";
import { APP_URL } from "../config";
import { CSSTransition } from "react-transition-group";

const mapStateToProps = ({
  Root: { currentUser },
  SystemNotifications: { data, newNotification, hasMore, readNotification }
}) => ({
  currentUser,
  data,
  newNotification,
  hasMore,
  readNotification
});
const mapDispatchToProps = () => dispatch => ({
  dispatch,
  queryNotifications: (userId, companyId, limit, offset) =>
    dispatch(queryNotifications(userId, companyId, limit, offset))
});

export class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationOpen: false,
      page: 0,
      hasMore: false
    };
    this.toggleNotification = this.toggleNotification.bind(this);
    this.markAsRead = this.markAsRead.bind(this);
    this.loadMoreNotifications = this.loadMoreNotifications.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }

  closeNotification() {
    this.setState({
      notificationOpen: false
    });
  }

  toggleNotification() {
    this.setState({
      notificationOpen: !this.state.notificationOpen
    });
    if (this.state.notificationsUnread > 0) {
      setTimeout(() => {
        markAllRead(this.props.currentUser.id).then(() => {
          this.setState({
            notificationsUnread: 0
          });
          let newNotifications = this.state.notifications;
          newNotifications.forEach(n => {
            n.viewed = true;
          });
          this.setState({
            notifications: newNotifications
          });
        });
      }, 5000);
    }
  }
  componentWillMount() {
    this.props.queryNotifications(
      this.props.currentUser.id,
      this.props.companyId,
      51,
      this.state.page * 50
    );
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.newNotification !== this.props.newNotification) {
      this.setState(
        {
          page: 0
        },
        () => {
          this.props.queryNotifications(
            this.props.currentUser.id,
            this.props.companyId,
            51,
            this.state.page
          );
        }
      );
    }
    if (nextProps.readNotification) {
      let newNotifications = this.state.notifications;
      newNotifications.forEach(n => {
        n.viewed = true;
      });
      this.setState({
        notificationsUnread: 0,
        notifications: newNotifications
      }, ()=>{
        this.props.dispatch({
          type: "UPDATE_READ_NOTIFICATION",
          message: false
        });
      });
    }
    if (nextProps.data !== this.props.data) {
      if (!this.state.notifications || this.state.page === 0) {
        this.setState({
          notifications: nextProps.data.notifications,
          notificationsUnread: nextProps.data.unView
        });
      } else {
        this.setState({
          notifications: this.state.notifications.concat(
            nextProps.data.notifications
          ),
          notificationsUnread: nextProps.data.unView,
          hasMore: nextProps.hasMore
        });
      }
    }

    if (nextProps.hasMore !== this.props.hasMore) {
      this.setState({
        hasMore: nextProps.hasMore
      });
    }

    if (nextProps.companyId !== this.props.companyId) {
      this.setState(
        {
          page: 0
        },
        () => {
          this.props.queryNotifications(
            this.props.currentUser.id,
            nextProps.companyId,
            51,
            0
          );
        }
      );
    }
  }

  markAsRead(notification) {
    let newNotification = notification;
    newNotification.viewed = true;
    markAsRead(newNotification).then(() => {
      this.setState({
        notificationsUnread: update(this.state.notificationsUnread, {
          $set: this.state.notificationsUnread - 1
        })
      });
      let index = this.state.notifications.indexOf(notification);
      if (index > -1) {
        this.setState({
          notifications: update(this.state.notifications, {
            index: { $set: newNotification }
          })
        });
      }
    });
  }

  loadMoreNotifications() {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.props.queryNotifications(
          this.props.currentUser.id,
          this.props.companyId,
          51,
          this.state.page * 50
        );
      }
    );
  }

  render() {
    let { notifications } = this.state;
    return (
      <li className="header__icon hide-below-tiny">
        <DropdownOutside onClickOutside={this.closeNotification}>
          <button
            className="button icon-only dropdown__toggle"
            type="button"
            title={T.translate("notifications")}
            onClick={() => {
              this.toggleNotification();
            }}
          >
            <i className="fa fa-bell-o" />
            {this.state.notificationsUnread
              ? <span className="notification__counter">
                  {this.state.notificationsUnread}
                </span>
              : null}
          </button>

          <CSSTransition
            in={this.state.notificationOpen}
            timeout={200}
            classNames="notification"
            unmountOnExit
          >
            {state =>
              <aside className="notification__aside">
                <div className="notification__header">
                  <h5>
                    {T.translate("notifications")}
                    <span>
                      ({this.state.notificationsUnread})
                    </span>
                  </h5>

                  <button
                    type="button"
                    className="notification__close icon-only"
                    onClick={() => {
                      this.closeNotification();
                    }}
                  >
                    <i className="fa fa-close" />
                  </button>
                </div>

                <ul className="notification__list">
                  {notifications && notifications.length === 0
                    ? <li className="notification__empty">
                        {T.translate("notification.empty")}
                      </li>
                    : null}
                  {notifications && notifications.length > 0
                    ? notifications.map((notification, i) =>
                        <li key={i} className="notification__item">
                          {notification.data.notification.type ===
                          "SEND_EMAIL_MESSAGE"
                            ? <a className="notification__link">
                                <div className="notification__body">
                                  {notification.data.notification.sourceUser
                                    ? <UserAvatar
                                        user={
                                          notification.data.notification
                                            .sourceUser
                                        }
                                        moreClasses="notification__user"
                                        className="notification__user"
                                      />
                                    : null}

                                  <h6 className="notification__heading">
                                    <span className="notification__publication-type">
                                      [{T.translate(
                                        "notification.actions.object.MESSAGE"
                                      )}]
                                    </span>

                                    <span
                                      className="notification__publication-name"
                                      title={
                                        notification.data.notification.message
                                          .title
                                      }
                                    >
                                      {
                                        notification.data.notification.message
                                          .title
                                      }
                                    </span>
                                  </h6>

                                  <p className="notification__text">
                                    {notification.data.notification.sourceUser
                                      ? notification.data.notification
                                          .sourceUser.name
                                      : null}{" "}
                                    {T.translate(
                                      "notification." +
                                        notification.data.notification.type
                                    )}
                                  </p>
                                </div>
                              </a>
                            : <a
                                target="_blank"
                                className="notification__link"
                                href={
                                  APP_URL +
                                  `/companies/` +
                                  notification.companyId +
                                  `/publications/` +
                                  notification.data.notification.publication.id
                                }
                              >
                                {notification.data.notification.company.name
                                  ? <div className="notification__company">
                                      {
                                        notification.data.notification.company
                                          .name
                                      }
                                    </div>
                                  : null}

                                <div className="notification__body">
                                  {notification.data.notification.sourceUser
                                    ? <UserAvatar
                                        moreClasses="notification__user"
                                        user={
                                          notification.data.notification
                                            .sourceUser
                                        }
                                        className="notification__user"
                                      />
                                    : null}

                                  <h6 className="notification__heading">
                                    <span className="notification__publication-type">
                                      [{T.translate(
                                        "publication." +
                                          notification.data.notification
                                            .publication.type
                                      )}]
                                    </span>{" "}
                                    <span
                                      className="notification__publication-name"
                                      title="{notification.data.notification.publication.name}"
                                    >
                                      {
                                        notification.data.notification
                                          .publication.name
                                      }
                                    </span>
                                  </h6>

                                  <p className="notification__text">
                                    {notification.data.notification.sourceUser
                                      ? notification.data.notification
                                          .sourceUser.name
                                      : null}{" "}
                                    {T.translate(
                                      "notification." +
                                        notification.data.notification.type
                                    )}{" "}
                                    {T.translate(
                                      "publication." +
                                        notification.data.notification
                                          .publication.type
                                    )}
                                  </p>
                                  {notification.data.notification.message &&
                                  notification.data.notification.type !==
                                    "COMMENT"
                                    ? <p className="notification__message">
                                        {notification.data.notification.message}
                                      </p>
                                    : null}
                                </div>
                              </a>}

                          {!notification.viewed
                            ? <button
                                type="button"
                                className="notification__remove icon-only"
                                title={T.translate("notification.mark-as-read")}
                                onClick={() => {
                                  this.markAsRead(notification);
                                }}
                              />
                            : null}
                        </li>
                      )
                    : null}

                  {this.state.hasMore
                    ? <li className="notification__footer">
                        <button
                          type="button"
                          onClick={() => {
                            this.loadMoreNotifications();
                          }}
                        >
                          <i className="fa fa-bell-o" />
                          <span>
                            {T.translate("notification.show-more")} …
                          </span>
                        </button>
                      </li>
                    : null}
                </ul>
              </aside>}
          </CSSTransition>
        </DropdownOutside>
      </li>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
