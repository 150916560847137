import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_GROUP_CATEGORIES = "QUERY_COMPANY_GROUP_CATEGORIES";

export const queryCompanyGroupCategories = (companyId, params = {}) => {
  let queryStr = qs.stringify(params, { addQueryPrefix: true });
  return {
    type: QUERY_COMPANY_GROUP_CATEGORIES,
    payload: APIUtils.get(`/companies/${companyId}/group-category${queryStr}`)
  };
};
