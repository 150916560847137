import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_EMAIL_TEMPLATES = "QUERY_EMAIL_TEMPLATES";
export const GET_EMAIL_TEMPLATE = "GET_EMAIL_TEMPLATE";
export const CREATE_EMAIL_TEMPLATE = "CREATE_EMAIL_TEMPLATE";
export const UPDATE_EMAIL_TEMPLATE = "UPDATE_EMAIL_TEMPLATE";
export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE";
export const GET_EMAIL_TEMPLATE_DEFAULT = "GET_EMAIL_TEMPLATE_DEFAULT";
export const LIMIT = 20;


export const fetch = (companyId, params = {}) => {
  let { page, ...query } = params;
  query.limit = LIMIT + 1;
  query.offset = 0;
  query.companyId = companyId;
  if (parseInt(params.page) > 0) {
    query.offset = params.page * query.limit - 1;
  }
  return {
    type: QUERY_EMAIL_TEMPLATES,
    payload: APIUtils.get(`/companies/mail-templates/?${qs.stringify(query)}`),
  };
};

export const get = (id) => ({
  type: GET_EMAIL_TEMPLATE,
  payload: APIUtils.get(`/companies/mail-templates/${id}/`),
});

export const create = (data) => ({
  type: CREATE_EMAIL_TEMPLATE,
  payload: APIUtils.post(`/companies/mail-templates/`, data),
});

export const update = (id, data) => ({
  type: UPDATE_EMAIL_TEMPLATE,
  payload: APIUtils.put(`/companies/mail-templates/${id}/`, data),
});

export const del = (id) => ({
  type: DELETE_EMAIL_TEMPLATE,
  payload: APIUtils.del(`/companies/mail-templates/${id}/`)
});

export const getTemplateDefault = (type, language) => ({
  type: GET_EMAIL_TEMPLATE_DEFAULT,
  payload: APIUtils.get(`/companies/mail-templates/default?type=${type}&lang=${language}`),
});
