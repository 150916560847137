import APIUtils from "../../utils/APIUtils";
import qs from "qs";
export let LIMIT = 20;
export const QUERY_COMPANY_UNITS = "QUERY_COMPANY_UNITS";
export const QUERY_COMPANY_USER_UNITS = "QUERY_COMPANY_USER_UNITS";
export const CREATE_COMPANY_UNIT = "CREATE_COMPANY_UNIT";
export const GET_COMPANY_UNIT = "GET_COMPANY_UNIT";
export const GET_OBJECT_SKILL = "GET_OBJECT_SKILL";
export const UPDATE_COMPANY_UNIT = "UPDATE_COMPANY_UNIT";
export const QUERY_COMPANY_UNIT_CATEGORIES = "QUERY_COMPANY_UNIT_CATEGORIES";
export const DELETE_COMPANY_UNIT = "DELETE_COMPANY_UNIT";
export const QUERY_UNIT_FILES = "QUERY_UNIT_FILES";
export const QUERY_COMPANY_SKILLS = "QUERY_COMPANY_SKILLS";
export const GET_COUNT_UNITS = "GET_COUNT_UNITS";
export const QUERY_COMPANY_UNIT_PRODUCTS = "QUERY_COMPANY_UNIT_PRODUCTS";

export const queryCompanyUnits = (companyId, filter = {}) => {
	let params = filter;
	if(!filter.limit){
		params.limit = LIMIT+1;
	}else{
		LIMIT = params.limit-1;
	}
	if (filter.page) {
		params.offset = parseInt(filter.page, 10) * LIMIT;
	}else{
		params.offset = 0;
	}

	return {
		type: filter.userId?QUERY_COMPANY_USER_UNITS:QUERY_COMPANY_UNITS,
		payload: APIUtils.get(
			`/companies/${companyId}/units?${qs.stringify(params)}`
		),
	};
};

export const queryUnitFiles = (companyId, unitId, filter = {}) => {
	let params = { limit: 21, offset: 0 };

	if (filter.page) {
		params.offset = parseInt(filter.page, 10) * 20;
	}

	return {
		type: QUERY_UNIT_FILES,
		payload: APIUtils.get(
			`/companies/${companyId}/units/${unitId}/files/?${qs.stringify(
				params
			)}`
		),
	};
};

export const deleteFileUnit = (companyId, unitId, fileId) => ({
	type: "DELETE_FILE_UNIT",
	payload: APIUtils.del(
		`/companies/${companyId}/units/${unitId}/files/${fileId}`
	),
});

export const createCompanyUnit = (companyId, unit) => ({
	type: CREATE_COMPANY_UNIT,
	payload: APIUtils.post(`/companies/${companyId}/units`, unit),
});

export const getCompanyUnit = (companyId, unitId) => ({
	type: GET_COMPANY_UNIT,
	payload: APIUtils.get(`/companies/${companyId}/units/${unitId}`),
});

export const getCountUnits = (companyId) => ({
	type: GET_COUNT_UNITS,
	payload: APIUtils.get(`/companies/${companyId}/units/count`),
});

export const updateCompanyUnit = (companyId, unit) => ({
	type: UPDATE_COMPANY_UNIT,
	payload: APIUtils.put(`/companies/${companyId}/units/${unit.id}`, unit),
});

export const deleteCompanyUnit = (companyId, unit) => ({
	type: DELETE_COMPANY_UNIT,
	payload: APIUtils.del(`/companies/${companyId}/units/${unit.id}`),
});

export const CompanyObjectSkillsService = (companyId, objectSkillId) => ({
	type: GET_OBJECT_SKILL,
	payload: APIUtils.get(
		`/companies/${companyId}/object-skills` + objectSkillId + "?limit=1000"
	),
});

export const queryCompanyUnitCategories = (companyId) => ({
	type: QUERY_COMPANY_UNIT_CATEGORIES,
	payload: APIUtils.get(`/companies/${companyId}/units/categories?limit=100`),
});

export const queryCompanySkills = (companyId) => ({
	type: QUERY_COMPANY_SKILLS,
	payload: APIUtils.get(`/companies/${companyId}/skills?limit=1000`),
});
export const queryCompanyUnitProducts = (companyId, unitId, filter = {}) => {
	let params = filter;
	if(!filter.limit){
		params.limit = LIMIT+1;
	}else{
		LIMIT = params.limit-1;
	}
	if (filter.page) {
		params.offset = parseInt(filter.page, 10) * LIMIT;
	}else{
		params.offset = 0;
	}

	return {
		type: QUERY_COMPANY_UNIT_PRODUCTS,
		payload: APIUtils.get(
			`/companies/${companyId}/units/${unitId}/products?${qs.stringify(params)}`
		),
	};
};

export const createCompanyUnitProduct = (companyId, unitId, unitProduct) => ({
	type: 'CREATE_COMPANY_UNIT_PRODUCT',
	payload: APIUtils.post(`/companies/${companyId}/units/${unitId}/products`, unitProduct),
});

export const updateCompanyUnitProduct = (companyId, unitId, unitProduct) => ({
	type: "UPDATE_COMPANY_UNIT_PRODUCT",
	payload: APIUtils.put(`/companies/${companyId}/units/${unitId}/products/${unitProduct.id}`, unitProduct),
});

export const deleteCompanyUnitProduct = (companyId, unitId, unitProductid) => ({
	type: "DELETE_COMPANY_UNIT_PRODUCT",
	payload: APIUtils.del(
		`/companies/${companyId}/units/${unitId}/products/${unitProductid}`
	),
});

export const createCompanyUnitUser = (companyId, unitId, userUnit) => ({
	type: 'CREATE_COMPANY_UNIT_USER',
	payload: APIUtils.post(`/companies/${companyId}/units/${unitId}/users`, userUnit),
});

export const deleteCompanyUnitUser = (companyId, unitId, userId) => ({
	type: "DELETE_COMPANY_UNIT_USER",
	payload: APIUtils.del(
		`/companies/${companyId}/units/${unitId}/users/${userId}`
	),
});
