import qs from "qs";
import APIUtils from "../../utils/APIUtils";

export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_INFO = "GET_COMPANY_INFO";
export const GET_COMPANY_LOGO = "GET_COMPANY_LOGO";
export const GET_COMPANY_LOGO_PDF = "GET_COMPANY_LOGO_PDF";
export const UPDATE_COMPANY_INFO = "UPDATE_COMPANY_INFO";
export const QUERY_COMPANY_FILES = "QUERY_COMPANY_FILES";
export const QUERY_COMPANY_CATEGORIES = "QUERY_COMPANY_CATEGORIES";
export const QUERY_COMPANY_PRIORITIES = "QUERY_COMPANY_PRIORITIES";
export const GET_COMPANY_USER_PERMISSIONS = "GET_COMPANY_USER_PERMISSIONS";
export const QUERY_COMPANY_TASK_TYPES = "QUERY_COMPANY_TASK_TYPES";
export const QUERY_COMPANY_TAGS = "QUERY_COMPANY_TAGS";
export const CREATE_COMPANY_GROUP_USER = "CREATE_COMPANY_GROUP_USER";

export const getCompany = (companyId) => ({
	type: GET_COMPANY,
	payload: APIUtils.get(
		`/companies/${companyId}`
	)
});

export const getCompanyInfo = (companyId) => ({
	type: GET_COMPANY_INFO,
	payload: APIUtils.get(
		`/companies/${companyId}/info`
	)
});

export const updateCompanyInfo = (companyId, companyInfo) => ({
	type: UPDATE_COMPANY_INFO,
	payload: APIUtils.put(
		`/companies/${companyId}/info`,
		companyInfo
	)
});

export const getCompanyLogo = (companyId, type) => ({
	type: type?GET_COMPANY_LOGO_PDF:GET_COMPANY_LOGO,
	payload: APIUtils.get(
		`/companies/${companyId}/logo?${type?("type="+type):""}`
	)
});

export const queryFiles = (companyId, params = {}) => {
    return {
        type: QUERY_COMPANY_FILES,
        payload: APIUtils.get(
            `/companies/${companyId}/files?${qs.stringify(params)}`
        )
    };
};

export const queryCategories = (companyId, params = {}) => {
    return {
        type: QUERY_COMPANY_CATEGORIES,
        payload: APIUtils.get(
            `/companies/${companyId}/categories?${qs.stringify(params)}`
        )
    };
};

export const queryPriorities = (companyId, params = {}) => {
    return {
        type: QUERY_COMPANY_PRIORITIES,
        payload: APIUtils.get(
            `/companies/${companyId}/priorities?${qs.stringify(params)}`
        )
    };
};

export const deleteFile = (companyId, fileId) => ({
	type: "DELETE_COMPANY_FILE",
	payload: APIUtils.del(
		`/companies/${companyId}/files/${fileId}`
	)
});

export const getCompanyUserPermissions = (companyId, userId) => ({
	type: GET_COMPANY_USER_PERMISSIONS,
	payload: APIUtils.get(`/companies/${companyId}/users/${userId}/permissions`)
});

export const queryTaskTypes = (companyId, params = {}) => {
    return {
        type: QUERY_COMPANY_TASK_TYPES,
        payload: APIUtils.get(
            `/companies/${companyId}/reminder-type?${qs.stringify(params)}`
        )
    };
};

export const queryTags = (companyId) => ({
    type: QUERY_COMPANY_TAGS,
    payload: APIUtils.get(`/companies/${companyId}/tags`),
});