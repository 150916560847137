const React = require('react');
const enhanceWithClickOutside = require('react-click-outside');

const DropdownOutside = (() => {
  	class Dropdown extends React.Component {
	    handleClickOutside() {
	      	this.props.onClickOutside();
	    }

	    render() {
	      	return <div className={this.props.className}>{this.props.children}</div>;
	    }
  	}

  	return enhanceWithClickOutside(Dropdown);
})();
export default DropdownOutside;