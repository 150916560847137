import {
    QUERY_EMAIL_TEMPLATES,
    GET_EMAIL_TEMPLATE,
    CREATE_EMAIL_TEMPLATE,
    UPDATE_EMAIL_TEMPLATE,
    DELETE_EMAIL_TEMPLATE,
    LIMIT,
} from "../../../actions/company/email-templates/CompanyEmailTemplateAction";
  
  export default function emailTemplates(
    state = {
        emailTemplates: [],
        emailTemplate: null,
        isFetching: true,
        isCreating: false,
        isUpdating: false,
        hasMore: false,
        isDeleting: false,
    },
    action
  ) {
    switch (action.type) {
        case QUERY_EMAIL_TEMPLATES + "_PENDING":
            return {
                ...state,
                isFetching: true,
                emailTemplates: [],
            };
    
        case QUERY_EMAIL_TEMPLATES + "_FULFILLED":
            return {
                ...state,
                emailTemplates: action.payload.body.slice(0, LIMIT),
                hasMore: action.payload.body.length > LIMIT,
                isFetching: false,
            };

        case QUERY_EMAIL_TEMPLATES + "_REJECTED":
            return {
                ...state,
                isFetching: false
            };
    
        case CREATE_EMAIL_TEMPLATE + "_PENDING":
            return {
                ...state,
                isCreating: true,
            };
        case CREATE_EMAIL_TEMPLATE + "_FULFILLED":
            return {
                ...state,
                isCreating: false,
            };

        case CREATE_EMAIL_TEMPLATE + "_REJECTED":
            return {
                ...state,
                isCreating: false,
            };
    
        case UPDATE_EMAIL_TEMPLATE + "_PENDING":
            return {
                ...state,
                isUpdating: true,
            };
        case UPDATE_EMAIL_TEMPLATE + "_FULFILLED":
            return {
                ...state,
                isUpdating: false,
            };
        case UPDATE_EMAIL_TEMPLATE + "_REJECTED":
            return {
                ...state,
                isUpdating: false,
            };
        case GET_EMAIL_TEMPLATE + "_PENDING":
            return {
                ...state,
                emailTemplate: null,
            };
        case GET_EMAIL_TEMPLATE + "_FULFILLED":
            return {
                ...state,
                emailTemplate: action.payload.body,
            };
        case DELETE_EMAIL_TEMPLATE + "_PENDING":
            return {
                ...state,
                isDeleting: true,
            }
        case DELETE_EMAIL_TEMPLATE + "_FULFILLED":
            return {
                ...state,
                isDeleting: false,
            }
        case DELETE_EMAIL_TEMPLATE + "_REJECTED":
            return {
                ...state,
                isDeleting: false,
            }
    
        default:
            return state;
    }
  }
  