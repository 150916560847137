const AUTH = (state, action) => {
  switch (action.type) {
    case "LOGIN_FAILURE":
      if (window.localStorage) {
        delete window.localStorage.token;
        delete window.localStorage.userId;
        delete window.localStorage.language;
        delete window.localStorage.selectedCompanyObj;
      }
      return {
        ...state,
        token: null,
        userId: null,
        code: action.code,
        phone: action.phone
      };

    case "LOGIN_SUCCESS":
      if (window.localStorage) {
        window.localStorage.setItem("token", action.token);
        window.localStorage.setItem("userId", action.userId);
      }
      return {
        ...state,
        token: action.token,
        userId: action.userId,
        msg: ""
      };

    case "RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        msg: action.msg
      };

    case "RESET_PASSWORD_FAILURE":
      return {
        ...state,
        msg: action.msg
      };

    default:
      return state || false;
  }
};

export default AUTH;
