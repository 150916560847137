import {
    QUERY_CHECKLISTS,
    CREATE_CHECKLIST
} from "../../../actions/company/checklists/CompanyChecklistActions";

const Checklist = (
    state = { checklists: [], checklist: {} },
    action
) => {
    switch (action.type) {
        case QUERY_CHECKLISTS + "_FULFILLED":
            return {
                ...state,
                checklists: action.payload.body
            };
        case CREATE_CHECKLIST + "_FULFILLED":
            return {
                ...state,
                checklist: action.payload.body
            };

        default:
            return state;
    }
};

export default Checklist;

