import React from "react";
import T from "i18n-react";
import moment from "moment";
import queryString from "query-string";
import { connect } from "react-redux";
import uuidV4 from "../utils/uuid";
import { Link } from "react-router-dom";
import { push } from "connected-react-router";
import Flatpickr from "react-flatpickr";
import se from "../utils/flatpickrLang";
import update from "immutability-helper";
import config from "../config";
import { queryCategories } from "../actions/company/CompanyActions";
import { queryNoticeService } from "../actions/company/list/CompanyNoticeAction";
import ModalMassEdit from "../components/ModalMassEdit";
import { queryCustomerPortals } from "../actions/company/customer-portals/CompanyCustomerPortalActions";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import classNames from "classnames";
import { ARGB2HexFilter } from "../utils/ARGB2Hex";
import { getCompanyUser } from "../actions/company/user/user";
import APIUtils from "../utils/APIUtils";
import ClickOutside from "./ClickOutside";
const mapStateToProps = ({
  NoticeService: { notices, hasMore },
  Company: { categories },
}) => {
  return {
    notices,
    categories,
    hasMore,
  };
};

const mapDispatchToProps = () => (dispatch) => ({
  dispatch,
  queryNoticeService: (companyId, params) =>
    dispatch(queryNoticeService(companyId, params)),
});

export class ListReminder extends React.Component {
  state = {
    companyId: "",
    publicationId: "",
    selectedAll: false,
    watcherStr: "",
    massEdit: {
      ids: [],
      enddate: null,
      noticePeriod: null,
      reminderAt: null,
      assignees: null,
      members: [],
      exclude: [],
    },
    hash: uuidV4(),
    toggleOpenOrder: false,
    status: "ongoing",
    limit: 20,
    query: {
      completed: false,
      q: "",
      manager: "",
      from: "",
      to: "",
    },
    searchTimeout: null,
    users: [],
    assignees: [],
  };

  onChangerFilterByManger = (e) => {
    let query = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.setState(
      {
        query: update(this.state.query, {
          manager: { $set: e.target.value },
        }),
      },
      () => {
        query = Object.assign({}, query, this.state.query);
        Object.keys(query).map((key, index) => {
          if (!query[key]) {
            delete query[key];
          }
        });
        this.props.dispatch(
          push({
            pathname: this.props.match.pathname,
            search: queryString.stringify(query),
          })
        );
      }
    );
  };
  checkReminderDate = (reminderAt) => {
    let dateBefore =
      (new Date(reminderAt).getTime() - new Date().getTime()) / 86400000;
    if (dateBefore > 0 && dateBefore <= 7) {
      return "#f29a25";
    } else if (dateBefore > 7 && dateBefore <= 30) {
      return "#759242";
    } else if (dateBefore <= 0) {
      return "#f26a5d";
    }
    return "none";
  };
  checkDueAt = () => {
    let query = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.setState(
      {
        query: update(this.state.query, {
          from: {
            $set:
              this.state.query.from && this.state.query.from !== "Invalid date"
                ? moment(this.state.query.from).format("YYYY-MM-DD")
                : "",
          },
          to: {
            $set:
              this.state.query.to && this.state.query.to !== "Invalid date"
                ? moment(this.state.query.to).format("YYYY-MM-DD")
                : "",
          },
        }),
      },
      () => {
        query = Object.assign({}, query, this.state.query);
        Object.keys(query).map((key, index) => {
          if (!query[key]) {
            delete query[key];
          }
        });
        this.props.dispatch(
          push({
            pathname: this.props.match.pathname,
            search: queryString.stringify(query),
          })
        );
      }
    );
  };
  filterNotice(param) {
    let query = param;
    if (param) {
      query = queryString.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      });
      query = Object.assign({}, query, param);
      Object.keys(query).map((key, index) => {
        if (!query[key]) {
          delete query[key];
        }
      });
    }
    if (!param) {
      query = {};
    }
    this.props.dispatch(
      push({
        pathname: this.props.match.pathname,
        search: queryString.stringify(query),
      })
    );
  }
  toggleSortNotice = (propertyName) => {
    let notices = this.state.notices.sort((a, b) => {
      if (propertyName === "noticeReminderAt") {
        return this.state.reverse
          ? a.reminderAt < b.reminderAt
            ? -1
            : 1
          : a.reminderAt >= b.reminderAt
          ? -1
          : 1;
      } else if (propertyName === "noticeName") {
        return this.state.reverse
          ? a.title < b.title
            ? -1
            : 1
          : a.title >= b.title
          ? -1
          : 1;
      } else if (propertyName === "noticeEnddate") {
        return this.state.reverse
          ? a.enddate < b.enddate
            ? -1
            : 1
          : a.enddate >= b.enddate
          ? -1
          : 1;
      }
    });
    this.setState({
      propertyName: propertyName,
      reverse: !this.state.reverse,
      notices: notices,
    });
  };

  searchPublication = (e) => {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      watcherStr: e.target.value,
      searchTimeout: setTimeout(() => {
        // this.props.dispatch(
        //   push({
        //     pathname: this.props.match.pathname,
        //     search: queryString.stringify(this.state.query),
        //   })
        // );

        let localSelectedCompany = window.localStorage.selectedCompanyObj
          ? JSON.parse(window.localStorage.selectedCompanyObj)
          : null;

        APIUtils.get(
          "/companies/" +
            localSelectedCompany.id +
            "/users?q=" +
            this.state.watcherStr +
            "&limit=10"
        ).then((resp) => {
          this.setState({
            toggleUser: true,
            users: resp.body,
          });
        });
      }, 300),
    });
  };

  componentDidMount() {
    let query = queryString.parse(this.props.location.search);
    let localSelectedCompany = window.localStorage.selectedCompanyObj
      ? JSON.parse(window.localStorage.selectedCompanyObj)
      : null;
    query.page = query.page || 0;

    this.props.dispatch(
      queryCategories(localSelectedCompany.id, {
        limit: 100,
        type: "REMINDER_CATEGORY",
      })
    );
    if (localSelectedCompany) {
      this.setState(
        {
          query: query,
          companyId: localSelectedCompany.id,
        },
        () => {
          let params = {
            limit: this.state.limit + 1,
            offset: query.page * this.state.limit,
            q: query.q,
            manager: query.manager,
            from: query.from,
            to: query.to,
            completed: query.completed ? query.completed : "false",
          };
          query = Object.assign({}, query, params);
          delete query.page;
          this.props.queryNoticeService(this.state.companyId, query);
        }
      );
    }
    if (query.q !== undefined) {
      this.setState({
        q: query.q,
      });
    }
    if (query.manager !== undefined) {
      this.setState({
        manager: query.manager,
      });
    }
    if (query.completed !== undefined) {
      if (query.completed === "true") {
        this.setState({
          status: "completed",
        });
      } else {
        this.setState({
          status: "ongoing",
        });
      }
    }
    this.setState({
      selectedAll: query.all ? true : false,
      massEdit: update(this.state.massEdit, {
        all: { $set: query.all ? true : false },
      }),
    });
    if (query.from !== undefined && query.to !== undefined) {
      this.setState({
        query: update(this.state.query, {
          from: { $set: query.from },
          to: { $set: query.to },
        }),
      });
    } else if (query.from !== undefined && query.to === undefined) {
      this.setState({
        query: update(this.state.query, {
          from: { $set: query.from },
          to: { $set: "" },
        }),
      });
    } else if (query.from === undefined && query.to !== undefined) {
      this.setState({
        query: update(this.state.query, {
          from: { $set: "" },
          to: { $set: query.to },
        }),
      });
    }
  }
  componentDidUpdate(nextProps) {
    if (this.props.notices !== nextProps.notices) {
      this.setState(
        {
          propertyName: "noticeReminderAt",
          reverse: true,
          notices: this.props.notices,
        },
        () => {
          this.toggleSortNotice(this.state.propertyName);
        }
      );
    }
    if (this.props.location.search !== nextProps.location.search) {
      let query = queryString.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      });
      query.page = query.page || 0;
      this.setState(
        {
          query: query,
        },
        () => {
          let params = {
            limit: this.state.limit + 1,
            offset: query.page * this.state.limit,
            q: query.q,
            manager: query.manager,
            from: query.from,
            to: query.to,
            completed: query.completed ? query.completed : "false",
          };
          query = Object.assign({}, query, params);
          delete query.page;
          this.props.queryNoticeService(this.state.companyId, query);
        }
      );
    }
  }

  setModalMassEditToggle = () => {
    this.setState({
      modalMassEdit: !this.state.modalMassEdit,
    });
  };

  setEmptyMassEdit = () => {
    this.setState({
      massEdit: {
        ids: [],
        enddate: null,
        noticePeriod: null,
        reminderAt: null,
        assignees: null,
        exclude: [],
      },
    });
  };

  addNoticesMassEdit = (noticeId) => {
    if (this.state.selectedAll) {
      if (this.state.massEdit.exclude.indexOf(noticeId) !== -1) {
        this.setState({
          massEdit: update(this.state.massEdit, {
            exclude: {
              $splice: [[this.state.massEdit.exclude.indexOf(noticeId), 1]],
            },
          }),
        });
      } else {
        this.setState({
          massEdit: update(this.state.massEdit, {
            exclude: { $push: [noticeId] },
          }),
        });
      }
    } else {
      if (this.state.massEdit.ids.indexOf(noticeId) !== -1) {
        this.setState({
          massEdit: update(this.state.massEdit, {
            ids: {
              $splice: [[this.state.massEdit.ids.indexOf(noticeId), 1]],
            },
          }),
        });
      } else {
        this.setState({
          massEdit: update(this.state.massEdit, {
            ids: { $push: [noticeId] },
          }),
        });
      }
    }
  };
  checkAllNotice = () => {
    this.setState(
      {
        query: update(this.state.query, {
          all: { $set: !this.state.selectedAll },
        }),
        selectedAll: !this.state.selectedAll,
        massEdit: update(this.state.massEdit, {
          all: { $set: !this.state.selectedAll },
          exclude: { $set: [] },
          ids: { $set: [] },
        }),
      },
      () => {
        let query = this.state.query;
        Object.keys(query).map((key, index) => {
          if (!query[key]) {
            delete query[key];
          }
        });
        this.props.dispatch(
          push({
            pathname: this.props.match.pathname,
            search: queryString.stringify(query),
          })
        );
      }
    );
  };

  render() {
    let query = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    let queryPage = this.state.query;
    let { massEdit } = this.state;
    return (
      <div>
        <div className="content-holder content-holder-wrap">
          <div className="form__wrap--embed cf">
            <header className="">
              <div className="row cf">
                <div className="customers-dashboard__header-column">
                  <h1>{T.translate("notices.notice-list")}</h1>
                </div>
                <div className="customers-dashboard__header-column">
                  <div
                    className="reminder__filter filter-right"
                    name="filterNoticeForm"
                  >
                    <div className="select-publication">
                      <input
                        type="text"
                        style={{height: "100%"}}
                        placeholder={T.translate("notices.filter-by-members")}
                        className="mw-typeahead__input"
                        value={this.state.watcherStr}
                        onChange={(e) => {
                          this.searchPublication(e);
                        }}
                      />
                      <ClickOutside
                        onClickOutside={() => {
                          this.setState({
                            toggleUser: false,
                          });
                        }}
                        className="select__wrap"
                      >
                        {this.state.toggleUser && this.state.users.length > 0 && (
                          <ul className="select__list">
                            {this.state.users.map((u) => (
                              <li
                                key={u.id}
                                onClick={() => {
                                  this.setState(
                                    {
                                      watcherStr: "",
                                      toggleUser: false,
                                      assignees: this.state.assignees.find(
                                        (a) => a.id === u.id
                                      )
                                        ? this.state.assignees
                                        : [...this.state.assignees, u],
                                    },
                                    () => {
                                      this.setState(
                                        {
                                          query: update(this.state.query, {
                                            assignee: {
                                              $set: this.state.assignees
                                                .map((a) => a.id)
                                                .join(","),
                                            },
                                          }),
                                        },
                                        () => {
                                          console.log(this.state.query);
                                          this.props.dispatch(
                                            push({
                                              pathname: this.props.match
                                                .pathname,
                                              search: queryString.stringify(
                                                this.state.query
                                              ),
                                            })
                                          );
                                        }
                                      );
                                    }
                                  );
                                }}
                                className="select__item text-height"
                              >
                                {u.email}
                              </li>
                            ))}
                          </ul>
                        )}
                      </ClickOutside>
                    </div>

                    <ul className="active-tags__list cf">
                      {this.state.assignees.map((a, i) => (
                        <li
                          key={a.id}
                          className="active-tags__tag active-tags__category"
                        >
                          <span>{a.name ? a.name : a.email}</span>
                          <button
                            type="button"
                            onClick={() => {
                              let assignees = this.state.assignees;
                              assignees.splice(i, 1);
                              this.setState(
                                {
                                  toggleUser: false,
                                  assignees,
                                },
                                () => {
                                  this.setState(
                                    {
                                      query: update(this.state.query, {
                                        assignee: {
                                          $set: this.state.assignees
                                            .map((a) => a.id)
                                            .join(","),
                                        },
                                      }),
                                    },
                                    () => {
                                      this.props.dispatch(
                                        push({
                                          pathname: this.props.match.pathname,
                                          search: queryString.stringify(
                                            this.state.query
                                          ),
                                        })
                                      );
                                    }
                                  );
                                }
                              );
                            }}
                          >
                            ×
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row cf reminder__notice-filter">
                <div className="reminder__filter">
                  <Flatpickr
                    placeholder={T.translate("timestamp.from-date")}
                    value={this.state.query.from}
                    onChange={(v) => {
                      this.setState(
                        {
                          query: update(this.state.query, {
                            from: {
                              $set: moment(new Date(v)).format("YYYY-MM-DD"),
                            },
                          }),
                        },
                        this.checkDueAt
                      );
                    }}
                    options={{
                      time_24hr: true,
                      locale: se,
                    }}
                  />
                </div>
                <div className="reminder__filter">
                  <Flatpickr
                    placeholder={T.translate("timestamp.to-date")}
                    value={this.state.query.to}
                    onChange={(v) => {
                      this.setState(
                        {
                          query: update(this.state.query, {
                            to: {
                              $set: moment(new Date(v)).format("YYYY-MM-DD"),
                            },
                          }),
                        },
                        this.checkDueAt
                      );
                    }}
                    options={{
                      time_24hr: true,
                      locale: se,
                    }}
                  />
                </div>
                <div className="reminder__filter">
                  <input
                    type="text"
                    id="filter-by-manager"
                    custom-autofocus="filter.manager.length > 0"
                    value={this.state.query.manager}
                    onChange={this.onChangerFilterByManger}
                    placeholder={T.translate("notices.filter-by-manager")}
                  />
                  {this.state.query.manager &&
                  this.state.query.manager.length !== 0 ? (
                    <button
                      className="reminder__clear-text"
                      onClick={() => {
                        this.setState(
                          {
                            query: update(this.state.query, {
                              manager: { $set: "" },
                            }),
                          },
                          () => {
                            query = Object.assign({}, query, this.state.query);
                            Object.keys(query).map((key, index) => {
                              if (!query[key]) {
                                delete query[key];
                              }
                            });
                            this.props.dispatch(
                              push({
                                pathname: this.props.match.pathname,
                                search: queryString.stringify(query),
                              })
                            );
                          }
                        );
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  ) : null}
                </div>
                <div className="reminder__filter">
                  <button
                    type="button"
                    disabled={!massEdit.ids.length && !this.state.selectedAll}
                    onClick={() => {
                      this.setState({
                        modalMassEdit: true,
                      });
                    }}
                    className="button button--positive"
                  >
                    {T.translate("notices.mass-edit")}
                  </button>
                </div>
                <form
                  className="reminder__filter"
                  name="filterNoticeForm"
                  onSubmit={(e) => {
                    e.preventDefault();
                    query = Object.assign({}, query, this.state.query);
                    Object.keys(query).map((key, index) => {
                      if (!query[key]) {
                        delete query[key];
                      }
                    });
                    this.props.dispatch(
                      push({
                        pathname: this.props.match.pathname,
                        search: queryString.stringify(query),
                      })
                    );
                  }}
                >
                  <div className="select-publication mw-typeahead--has-button">
                    <input
                      type="text"
                      placeholder={T.translate("notices.filter-by-name")}
                      className="mw-typeahead__input"
                      value={this.state.query.q}
                      onChange={(e) => {
                        this.setState({
                          query: update(this.state.query, {
                            q: { $set: e.target.value },
                          }),
                        });
                      }}
                    />
                  </div>
                  <button className="button mw-typeahead__button" type="submit">
                    {T.translate("search")}
                  </button>
                </form>

                <Dropdown
                  className="select__wrap reminder__filter"
                  isOpen={this.state.toggleCategory}
                  toggle={() => {
                    this.setState({
                      toggleCategory: !this.state.toggleCategory,
                    });
                  }}
                >
                  <DropdownToggle className="select__button button">
                    {this.state.query.categoryId &&
                    this.props.categories.length ? (
                      <>
                        <span
                          className="card-list__priority"
                          style={{
                            backgroundColor: ARGB2HexFilter(
                              this.props.categories.filter(
                                (category) =>
                                  category.id === this.state.query.categoryId
                              )[0].color
                            ),
                          }}
                        ></span>

                        {
                          this.props.categories.filter(
                            (category) =>
                              category.id === this.state.query.categoryId
                          )[0].name
                        }
                      </>
                    ) : (
                      T.translate("category")
                    )}

                    <i
                      className={
                        "fa " +
                        (this.state.toggleCategory
                          ? "fa-caret-up"
                          : "fa-caret-down")
                      }
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="select__list">
                      <li
                        className={classNames("select__item text-height", {
                          active: !this.state.query.categoryId,
                        })}
                        onClick={() => {
                          this.setState(
                            {
                              toggleCategory: false,
                              query: update(this.state.query, {
                                categoryId: {
                                  $set: "",
                                },
                              }),
                            },
                            () => {
                              query = Object.assign(
                                {},
                                query,
                                this.state.query
                              );
                              Object.keys(query).map((key, index) => {
                                if (!query[key]) {
                                  delete query[key];
                                }
                              });
                              this.props.dispatch(
                                push({
                                  pathname: this.props.match.pathname,
                                  search: queryString.stringify(query),
                                })
                              );
                            }
                          );
                        }}
                      >
                        {T.translate("none")}
                      </li>

                      {this.props.categories.map((c) => (
                        <li
                          key={c.id}
                          className={classNames("select__item text-height", {
                            active: this.state.query.categoryId === c.id,
                          })}
                          onClick={() => {
                            this.setState(
                              {
                                toggleCategory: false,
                                query: update(this.state.query, {
                                  categoryId: {
                                    $set: c.id,
                                  },
                                }),
                              },
                              () => {
                                query = Object.assign(
                                  {},
                                  query,
                                  this.state.query
                                );
                                Object.keys(query).map((key, index) => {
                                  if (!query[key]) {
                                    delete query[key];
                                  }
                                });
                                this.props.dispatch(
                                  push({
                                    pathname: this.props.match.pathname,
                                    search: queryString.stringify(query),
                                  })
                                );
                              }
                            );
                          }}
                        >
                          <span
                            className="card-list__priority"
                            style={{ backgroundColor: ARGB2HexFilter(c.color) }}
                          ></span>
                          {c.name}
                        </li>
                      ))}
                    </ul>
                  </DropdownMenu>
                </Dropdown>

                <Dropdown
                  className="select__wrap reminder__filter"
                  isOpen={this.state.toggleOpenOrder}
                  toggle={() => {
                    this.setState({
                      toggleOpenOrder: !this.state.toggleOpenOrder,
                    });
                  }}
                >
                  <DropdownToggle className="select__button button">
                    {this.state.status === "ongoing" ? (
                      <span>{T.translate("notices.ongoing")}</span>
                    ) : null}
                    {this.state.status === "completed" ? (
                      <span>{T.translate("task.completed")}</span>
                    ) : null}
                    <i
                      className={
                        "fa " +
                        (this.state.toggleOpenOrder
                          ? "fa-caret-up"
                          : "fa-caret-down")
                      }
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="select__list">
                      <li
                        className={classNames("select__item text-height", {
                          active: this.state.status === "ongoing",
                        })}
                        onClick={() => {
                          this.setState(
                            {
                              status: "ongoing",
                              toggleOpenOrder: false,
                              query: update(this.state.query, {
                                completed: {
                                  $set: "false",
                                },
                              }),
                            },
                            () => {
                              query = Object.assign(
                                {},
                                query,
                                this.state.query
                              );
                              Object.keys(query).map((key, index) => {
                                if (!query[key]) {
                                  delete query[key];
                                }
                              });
                              this.props.dispatch(
                                push({
                                  pathname: this.props.match.pathname,
                                  search: queryString.stringify(query),
                                })
                              );
                            }
                          );
                        }}
                      >
                        {T.translate("notices.ongoing")}
                      </li>
                      <li
                        className={classNames("select__item text-height", {
                          active: this.state.status === "completed",
                        })}
                        onClick={() => {
                          this.setState(
                            {
                              status: "completed",
                              toggleOpenOrder: false,
                              query: update(this.state.query, {
                                completed: {
                                  $set: "true",
                                },
                              }),
                            },
                            () => {
                              query = Object.assign(
                                {},
                                query,
                                this.state.query
                              );
                              Object.keys(query).map((key, index) => {
                                if (!query[key]) {
                                  delete query[key];
                                }
                              });
                              this.props.dispatch(
                                push({
                                  pathname: this.props.match.pathname,
                                  search: queryString.stringify(query),
                                })
                              );
                            }
                          );
                        }}
                      >
                        {T.translate("task.completed")}
                      </li>
                    </ul>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </header>
          </div>
          <div className="users-list__wrap row">
            <div className="table__wrap  table--theme table-td settings__table">
              <table className="footable customers-dashboard__footable-toggle customers-dashboard__notices table--break-small">
                <thead>
                  <tr>
                    <th>
                      <div className="checkbox__wrap">
                        <input
                          id={"checkAllNotice"}
                          className="checkbox__input fixedCheckbox-notices-list"
                          type="checkbox"
                          checked={this.state.selectedAll}
                          onClick={() => {
                            this.checkAllNotice();
                          }}
                        />
                        <label
                          htmlFor={"checkAllNotice"}
                          className="checkbox__label"
                        ></label>
                      </div>
                    </th>
                    <th>&nbsp;</th>
                    <th
                      className="text-left"
                      onClick={() => {
                        this.toggleSortNotice("noticeReminderAt");
                      }}
                    >
                      {T.translate("notices.reminder-date")}{" "}
                      <i
                        className={classNames("fa", {
                          "fa-caret-down":
                            this.state.propertyName === "noticeReminderAt" &&
                            !this.state.reverse,
                          "fa-caret-up":
                            this.state.propertyName !== "noticeReminderAt" ||
                            this.state.reverse,
                        })}
                      ></i>
                    </th>
                    <th
                      className="text-left"
                      onClick={() => {
                        this.toggleSortNotice("noticeName");
                      }}
                    >
                      {T.translate("name")}{" "}
                      <i
                        className={classNames("fa", {
                          "fa-caret-down":
                            this.state.propertyName === "noticeName" &&
                            !this.state.reverse,
                          "fa-caret-up":
                            this.state.propertyName !== "noticeName" ||
                            this.state.reverse,
                        })}
                      ></i>
                    </th>
                    <th className="text-center" data-hide="phone">
                      {T.translate("link")}{" "}
                    </th>
                    <th className="text-left" data-hide="phone">
                      {T.translate("description")}{" "}
                    </th>
                    <th
                      className="text-left"
                      data-hide="phone,tablet"
                      onClick={() => {
                        this.toggleSortNotice("noticeEnddate");
                      }}
                    >
                      {T.translate("notices.document-end-date")}{" "}
                      <i
                        className={classNames("fa", {
                          "fa-caret-down":
                            this.state.propertyName === "noticeEnddate" &&
                            !this.state.reverse,
                          "fa-caret-up":
                            this.state.propertyName !== "noticeEnddate" ||
                            this.state.reverse,
                        })}
                      ></i>
                    </th>
                    <th className="text-left" data-hide="phone,tablet">
                      {T.translate("manager")}{" "}
                    </th>
                    <th className="text-left">{T.translate("category")} </th>
                    <th className="text-right">{T.translate("actions")} </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.notices.length !== undefined
                    ? this.props.notices.map((notice, index) => (
                        <tr key={index}>
                          <td>
                            <div className="checkbox__wrap">
                              <input
                                id={"notice-" + notice.id}
                                className="checkbox__input fixedCheckbox-notices-list"
                                type="checkbox"
                                checked={
                                  (!this.state.selectedAll &&
                                    massEdit.ids.indexOf(notice.id) > -1) ||
                                  (this.state.selectedAll &&
                                    massEdit.exclude.indexOf(notice.id) === -1)
                                }
                                onClick={() => {
                                  this.addNoticesMassEdit(notice.id);
                                }}
                              />
                              <label
                                htmlFor={"notice-" + notice.id}
                                className="checkbox__label"
                              ></label>
                            </div>
                          </td>
                          <td
                            key={notice.id}
                            style={{
                              backgroundColor: this.checkReminderDate(
                                notice.reminderAt
                              ),
                            }}
                          ></td>
                          <td
                            data-header={T.translate("notices.reminder-date")}
                            className="text-left"
                          >
                            {moment(notice.reminderAt).format("YYYY-MM-DD")}
                          </td>
                          <td
                            data-header={T.translate("name")}
                            className="text-left"
                          >
                            {notice.title}
                          </td>
                          <td
                            data-header={T.translate("link")}
                            className="text-center"
                          >
                            <a
                              className="customer-portals__link"
                              target="_blank"
                              href={`${config.APP_URL}/companies/${this.state.companyId}/publications/${notice.publication.id}`}
                            >
                              <i className="fa fa-link" aria-hidden="true"></i>
                            </a>
                            {() => {
                              this.setState({
                                publicationId: notice.publication.id,
                              });
                            }}
                          </td>
                          <td
                            data-header={T.translate("description")}
                            className="text-left"
                          >
                            {notice.description !== null
                              ? notice.description
                              : ""}
                          </td>
                          <td
                            data-header={T.translate(
                              "notices.document-end-date"
                            )}
                            className="text-left"
                          >
                            {moment(notice.enddate).format("YYYY-MM-DD")}
                          </td>
                          <td
                            data-header={T.translate("manager")}
                            className="text-left"
                          >
                            {notice.manager !== null ? notice.manager.name : ""}
                          </td>
                          <td
                            data-header={T.translate("category")}
                            className="text-left"
                          >
                            {notice.companyCategory ? (
                              <>
                                <span
                                  className="card-list__priority"
                                  style={{
                                    backgroundColor: ARGB2HexFilter(
                                      notice.companyCategory.color
                                    ),
                                  }}
                                ></span>
                                {notice.title}
                              </>
                            ) : null}
                          </td>
                          <td
                            data-header={T.translate("actions")}
                            className="text-right actions__wrap edit-button"
                          >
                            <Link
                              className="actions__button"
                              type="button"
                              title={T.translate("edit")}
                              to={
                                "/list/reminder/" +
                                notice.id +
                                (query
                                  ? "?" + queryString.stringify(query)
                                  : "")
                              }
                            >
                              <i className="fa fa-edit"></i>
                            </Link>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
            <ul className="pagination row cf">
              <li className="line-component">
                {query.page > 0 ? (
                  <button
                    onClick={() => {
                      this.filterNotice({
                        page: parseInt(query.page, 10) - 1,
                      });
                    }}
                    className="button button--system"
                  >
                    <i className="fa fa-chevron-left"></i>
                    {T.translate("previous")}
                  </button>
                ) : null}
              </li>
              <li className="line-component--omega">
                {this.props.hasMore ? (
                  <button
                    onClick={() => {
                      this.filterNotice({
                        page: parseInt(queryPage.page, 10) + 1,
                      });
                    }}
                    className="button button--system"
                  >
                    {" "}
                    {T.translate("next")}{" "}
                    <i className="fa fa-chevron-right"></i>
                  </button>
                ) : null}
              </li>
            </ul>
          </div>
        </div>
        <div ui-view="reminder-detail"></div>
        {this.state.modalMassEdit ? (
          <ModalMassEdit
            toggle={this.setModalMassEditToggle}
            massEdit={this.state.massEdit}
            companyId={this.state.companyId}
          />
        ) : null}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListReminder);
