import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_INVESTIGATION_USERS = "QUERY_COMPANY_INVESTIGATION_USERS";
export const CREATE_COMPANY_INVESTIGATION_USER = "CREATE_COMPANY_INVESTIGATION_USER";
export const DELETE_COMPANY_INVESTIGATION_USER = "DELETE_COMPANY_INVESTIGATION_USER";

export const queryInvestigationUsers = (companyId, params = {}) => {
  let queryStr = qs.stringify(params, { addQueryPrefix: true });
  return {
    type: QUERY_COMPANY_INVESTIGATION_USERS,
    payload: APIUtils.get(`/companies/${companyId}/investigation-users${queryStr}`)
  };
};
export const createInvestigationUser = (companyId, user, unitId) => ({
  type: CREATE_COMPANY_INVESTIGATION_USER,
  payload: APIUtils.post(`/companies/${companyId}/investigation-users${unitId?'?unitId='+unitId:''}`, user)
});

export const deleteInvestigationUser = (companyId, investigationUserId) => ({
  type: DELETE_COMPANY_INVESTIGATION_USER,
  payload: APIUtils.del(`/companies/${companyId}/investigation-users/${investigationUserId}`)
});
