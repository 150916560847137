import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_ESIGN = "QUERY_ESIGN";

export const queryEsign = (companyId, params) => {
	let limit = 21,
		offset = 0;
	if (!params.status) {
		params.status = "active";
	}

	let page = parseInt(params.page, 10) | 0;
	offset = page * limit;

	let queryStr = qs.stringify(Object.assign({}, params, { limit, offset }), {
		addQueryPrefix: true
	});

	return {
		type: QUERY_ESIGN,
		payload: APIUtils.get(
			`/companies/${companyId}/e-signs-share${queryStr}`
		)
	};
};
