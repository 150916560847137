import qs from "qs";
import APIUtils from "../../utils/APIUtils";

export const QUERY_USER_LOGIN_LOGS = "QUERY_USER_LOGIN_LOGS";
export const QUERY_USER_LOGIN_APPS = "QUERY_USER_LOGIN_APPS";

export const queryUserLoginLogs = (params = {}) => {
  let queryStr = qs.stringify(params, { addQueryPrefix: true });
  return {
    type: QUERY_USER_LOGIN_LOGS,
    payload: APIUtils.get(`/companies/admin/tokens${queryStr}`)
  };
};

export const queryUserLoginApps = (params = {}) => {
  let queryStr = qs.stringify(params, { addQueryPrefix: true });
  return {
    type: QUERY_USER_LOGIN_APPS,
    payload: APIUtils.get(`/companies/admin/apps${queryStr}`)
  };
};
