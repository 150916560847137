import APIUtils from "../../../utils/APIUtils";
import qs from "qs";

export let LIMIT = 20;
export const QUERY_COMPANY_SKILLS = "QUERY_COMPANY_SKILLS";
export const GET_COMPANY_SKILL = "GET_COMPANY_SKILL";
export const CREATE_COMPANY_SKILL = "CREATE_COMPANY_SKILL";
export const QUERY_OBJECT_SKILLS = "QUERY_OBJECT_SKILLS";

export const queryCompanySkills = (companyId, filter = {}) => {
	let params = filter;
	if(!filter.limit){
		params.limit = LIMIT+1;
	}else{
		LIMIT = params.limit-1;
	}
	if (filter.page) {
		params.offset = parseInt(filter.page, 10) * LIMIT;
	}else{
		params.offset = 0;
	}

	return {
		type: QUERY_COMPANY_SKILLS,
		payload: APIUtils.get(
			`/companies/${companyId}/skills?${qs.stringify(params)}`
		),
	};
};

export const createCompanySkill = (companyId, skill) => ({
	type: CREATE_COMPANY_SKILL,
	payload: APIUtils.post(`/companies/${companyId}/units`, skill),
});

export const getCompanySkill = (companyId, skillId) => ({
	type: GET_COMPANY_SKILL,
	payload: APIUtils.get(`/companies/${companyId}/units/${skillId}`),
});

export const queryObjectSkills = (companyId, filter = {}) => {
	let params = filter;
	if (!filter.limit) {
		params.limit = LIMIT + 1
	}else{
		LIMIT = params.limit-1;
	}
	if (filter.page) {
		params.offset = parseInt(filter.page, 10) * LIMIT;
	}else{
		params.offset = 0;
	}

	return {
		type: QUERY_OBJECT_SKILLS,
		payload: APIUtils.get(
			`/companies/${companyId}/object-skills?${qs.stringify(params)}`
		),
	};
};

