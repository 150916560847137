import {
    QUERY_ADDRESS_BOOKS
  } from "../../actions/address-books/AddressBookActions";
  
  const AddressBook = (state = { contacts: [] }, action) => {
    switch (action.type) {
        case QUERY_ADDRESS_BOOKS + "_FULFILLED":
            return {
                ...state,
                contacts: action.payload.body
            };
        default:
            return state;
    }
  };
  
  export default AddressBook;
  