import React from "react";
import T from "i18n-react";
import uuidV4 from "../utils/uuid";
import { connect } from "react-redux";
import { goBack, push } from "connected-react-router";
import { queryNoticeDetail } from "../actions/company/list/CompanyNoticeAction";
import { queryCategories } from "../actions/company/CompanyActions";
import { Modal, ModalBody } from "reactstrap";
import Notifications from "react-notification-system-redux";
import Flatpickr from "react-flatpickr";
import classNames from "classnames";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import DropdownOutside from "./DropdownOutside";
import UserAvatar from "./UserAvatar";
import APIUtils from "../utils/APIUtils";
import { ARGB2HexFilter } from "../utils/ARGB2Hex";
import moment from "moment";
import UserPopover from "./UserPopover";
import QueryUser from "./QueryUser";
import update from "immutability-helper";
import se from "../utils/flatpickrLang";

const mapStateToProps = ({
  NoticeService: { noticeDetail },
  Company: { categories },
}) => {
  return {
    noticeDetail,
    categories,
  };
};

const mapDispatchToProps = () => (dispatch) => ({
  dispatch,
  queryNoticeDetail: (companyId, noticeId) =>
    dispatch(queryNoticeDetail(companyId, noticeId)),
  queryCategories: (companyId, params) =>
    dispatch(queryCategories(companyId, params)),
  goBack: () => dispatch(goBack()),
});

export class ModalDetail extends React.Component {
  state = {
    companyId: "",
    toggleModal: false,
    toggleForm: false,
    tag: "",
    notice: {
      title: "",
      description: "",
    },
    completed: false,
    saved: false,
  };
  componentDidMount() {
    let localSelectedCompany = window.localStorage.selectedCompanyObj
      ? JSON.parse(window.localStorage.selectedCompanyObj)
      : null;
    this.setState(
      {
        companyId: localSelectedCompany.id,
      },
      () => {
        this.props.queryNoticeDetail(
          this.state.companyId,
          this.props.match.params.id
        );
        this.props.queryCategories(this.state.companyId, {
          limit: 100,
          type: "REMINDER_CATEGORY",
        });
      }
    );
  }
  componentDidUpdate(prevProps) {
    if (this.props.noticeDetail !== prevProps.noticeDetail) {
      this.setState({
        notice: this.props.noticeDetail,
      });
    }
  }
  removeMember = (m) => {
    let notice = this.state.notice;
    if (m) {
      notice.assignees.splice(notice.assignees.indexOf(m), 1);
    }
    this.setState(
      {
        notice: notice,
      },
      () => {
        this.save();
      }
    );
  };

  checkDueAt = (notice) => {
    if (notice.enddate <= moment(notice.reminderAt).format("YYYY-MM-DD")) {
      notice.reminderAt = new Date(moment(notice.enddate).subtract(1, "month"));
      notice.noticePeriod = "1 month";
    }
    this.setState({
      notice: notice,
      hash: uuidV4(),
    });
  };

  saveNotice = (notice) => {
    return APIUtils.put(
      "/companies/" + notice.company.id + "/notices/" + notice.id,
      notice
    ).then(
      (resp) => {
        this.props.dispatch(
          Notifications.success({
            title: "",
            message: T.translate("task.update-success"),
            position: "tc",
            autoDismiss: 5,
          })
        );
      },
      () => {
        this.props.dispatch(
          Notifications.error({
            title: "",
            message: T.translate("task.update-failure"),
            position: "tc",
            autoDismiss: 5,
          })
        );
      }
    );
  };

  setCompleted = (notice) => {
    if (notice.status) {
      notice.status = false;
    } else {
      notice.completedAt = new moment(Date()).format("YYYY-MM-DDTHH:MM:SSZ");
      notice.status = true;
    }
    this.saveNotice(notice).then(() => {
      this.props.dispatch(this.props.goBack);
      window.location.href = "/list/reminder";
    });
  };

  save = () => {
    this.saveNotice(this.state.notice).then(
      () => {
        this.setState({
          orignNotice: Object.assign({}, this.state.notice),
          saved: true,
        });
      },
      () => {
        this.setState({
          notice: Object.assign({}, this.state.orignNotice),
        });
      }
    );
  };

  deleteNotice = (notice) => {
    return APIUtils.del(
      "/companies/" + notice.company.id + "/notices/" + notice.id
    ).then(
      (resp) => {
        this.props.dispatch(
          Notifications.success({
            title: "",
            message: T.translate("task.update-success"),
            position: "tc",
            autoDismiss: 5,
          })
        );
        window.location.href = "/list/reminder";
      },
      () => {
        this.props.dispatch(
          Notifications.error({
            title: "",
            message: T.translate("task.update-failure"),
            position: "tc",
            autoDismiss: 5,
          })
        );
      }
    );
  };

  addTag = (tag) => {
    this.setState({
      notice: update(this.state.notice, {
        tag: {
          $set: this.state.notice.tag
            ? [...this.state.notice.tag.split(","), tag].join(",")
            : tag,
        },
      }),
      tag: "",
    }, () => {
      this.save();
    });
  };

  removeTag = (index) => {
    let tags = this.state.notice.tag ? this.state.notice.tag.split(",") : [];
    tags.splice(index, 1);
    this.setState({
      notice: update(this.state.notice, {
        tag: {
          $set: tags.join(","),
        },
      }),
    }, () => {
      this.save();
    });
  };

  onChangeTag = (e) => {
    this.setState({
      tag: e.target.value,
    });
  };
  render() {
    let { completed, notice } = this.state;
    return (
      <Modal
        ref={(el) => (this.componentRef = el)}
        isOpen={!this.state.toggleModal}
        toggle={() => {}}
        className={"reminder-detail__wrapper"}
      >
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.save();
              this.setState({
                toggleForm: false,
              });
            }}
          >
            <div>
              <header className="reminder-detail__header">
                <h2>
                  <span className="reminder-detail__task-number">
                    <strong>{T.translate("notices.title")}</strong>
                  </span>
                </h2>
                <p>
                  <a
                    onClick={() => {
                      this.props.goBack();
                      window.location.href = "/list/reminder";
                    }}
                  >
                    {T.translate("notices.see-notice-list")}
                  </a>
                </p>
                <div className="publication-edit__wrap reminder-detail__edit-btn print-hide cf">
                  <button
                    type="button"
                    onClick={() => {
                      this.setCompleted(this.state.notice);
                    }}
                    className={
                      "button " +
                      (!this.props.noticeDetail.status
                        ? "reminder-detail__btn--complete"
                        : "")
                    }
                  >
                    {this.props.noticeDetail.status ? (
                      <span>
                        <i className="fa fa-check" /> {T.translate("undo")}
                      </span>
                    ) : (
                      T.translate("task.completed")
                    )}
                  </button>
                  <a
                    onClick={() => {
                      this.props.goBack();
                      if (this.state.saved) {
                        window.location.href = "/list/reminder";
                      }
                    }}
                    className="button reminder-detail__btn--negative"
                  >
                    <i className="fa fa-times" />
                    <span>{T.translate("close")}</span>
                  </a>
                </div>
              </header>

              <div className="cf">
                <div className="reminder-detail__column reminder-detail__content">
                  <div className="row--large">
                    <label className="text-height">
                      {T.translate("name")} <span className="required"></span>
                    </label>
                    <div>
                      <input
                        value={this.state.notice.title}
                        disabled={this.state.toggleForm ? "" : "disabled"}
                        onChange={(e) => {
                          this.setState({
                            notice: update(this.state.notice, {
                              title: {
                                $set: e.target.value,
                              },
                            }),
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row--large">
                    <label className="text-height">
                      {T.translate("task.description")}
                    </label>
                    <textarea
                      disabled={this.state.toggleForm ? "" : "disabled"}
                      value={this.state.notice.description}
                      onChange={(e) => {
                        this.setState({
                          notice: update(this.state.notice, {
                            description: {
                              $set: e.target.value,
                            },
                          }),
                        });
                      }}
                    />
                  </div>
                  <div className="row--large">
                    <label className="text-height">
                      {T.translate("task.connected-object")}
                    </label>
                    <div class="reminder-detail__connection">
                      <div class="set-parent__wrap has-connection">
                        <a
                          class="set-parent__body"
                          href="https://app.beta.dokiv.com/companies/c2073d12-30f2-4863-afd1-ee576bb5934d/publications/b7862db4-6b1e-44d5-ad8d-fc9212c205f1"
                        >
                          <span class="set-parent__sitemap">
                            <i class="fa fa-sitemap"></i>
                          </span>
                          <h5
                            class="set-parent__name"
                            title={
                              this.props.noticeDetail.publication
                                ? this.props.noticeDetail.publication.name
                                : ""
                            }
                          >
                            {this.props.noticeDetail.publication
                              ? this.props.noticeDetail.publication.name
                              : ""}
                          </h5>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row--large input-date">
                    <QueryUser
                      disabled={!this.state.toggleForm}
                      companyId={this.state.companyId}
                      queryType="manager"
                      onAfterSelect={(m) => {
                        this.setState({
                          notice: update(this.state.notice, {
                            manager: {
                              $set: m,
                            },
                          }),
                        });
                      }}
                      key={this.state.hash}
                      items={this.state.notice.manager}
                    />
                  </div>
                </div>
                <div className="reminder-detail__column column-right">
                  <div className="row">
                    <label> {T.translate("notices.document-end-date")} </label>
                    <Flatpickr
                      disabled={this.state.toggleForm ? "" : "disabled"}
                      onChange={(v) => {
                        this.setState(
                          {
                            notice: update(this.state.notice, {
                              enddate: {
                                $set: new Date(v),
                              },
                            }),
                          },
                          () => {
                            this.setState({
                              notice: update(this.state.notice, {
                                noticePeriod: { $set: "1 month" },
                                reminderAt: {
                                  $set: new Date(
                                    moment(this.state.notice.enddate).subtract(
                                      1,
                                      "month"
                                    )
                                  ),
                                },
                              }),
                            });
                          }
                        );
                      }}
                      value={moment(this.state.notice.enddate).format(
                        "YYYY-MM-DD"
                      )}
                      key={`enable-time-${this.state.hash}`}
                      options={{
                        locale: se,
                      }}
                    />
                  </div>
                  <div className="row">
                    <label>{T.translate("notices.reminder-date")}</label>
                    <Flatpickr
                      disabled={this.state.toggleForm ? "" : "disabled"}
                      onChange={(v) => {
                        this.setState(
                          {
                            notice: update(this.state.notice, {
                              reminderAt: {
                                $set: new Date(v),
                              },
                            }),
                          },
                          () => {
                            this.checkDueAt(this.state.notice);
                          }
                        );
                      }}
                      value={moment(this.state.notice.reminderAt).format(
                        "YYYY-MM-DD"
                      )}
                      key={`enable-time-${this.state.hash}`}
                      options={{
                        locale: se,
                      }}
                    />
                  </div>
                  <div className="row">
                    <h4 className="text-height">
                      {T.translate("task.members")}:
                    </h4>
                    <ul className="cf">
                      {this.state.notice.assignees
                        ? this.state.notice.assignees.map((v) => (
                            <li key={v.id}>
                              <UserPopover
                                disabled={
                                  this.state.notice.assignees.length === 1
                                }
                                onDelete={() => {
                                  this.removeMember(v);
                                }}
                                user={v}
                              />
                            </li>
                          ))
                        : null}
                    </ul>
                    <QueryUser
                      companyId={this.state.companyId}
                      disabled={this.state.toggleForm ? "" : "disabled"}
                      onAfterSelect={(v) => {
                        let assignees = this.state.notice.assignees;
                        assignees.push(v);
                        this.setState(
                          {
                            notice: update(this.state.notice, {
                              assignees: {
                                $set: assignees,
                              },
                            }),
                          },
                          () => {
                            this.save();
                          }
                        );
                      }}
                      items={this.state.notice.assignees}
                    />
                  </div>
                  <div className="row">
                    <div className="separator">
                      <h4 className="connections__header">
                        {T.translate("tags")}:
                      </h4>
                      <ul className="tag__list row">
                        {this.state.notice.tag
                          ? this.state.notice.tag.split(",").map((t, i) => (
                              <li key={i} className="tag__item">
                                <span>{t}</span>
                                <button
                                  className="tag__remove"
                                  onClick={() => {
                                    this.removeTag(i);
                                  }}
                                  type="button"
                                >
                                  ×
                                </button>
                              </li>
                            ))
                          : null}
                      </ul>
                      <div className="row--small">
                        <div className="row--small">
                          <input
                            placeholder={T.translate("tag.add")}
                            value={this.state.tag}
                            onChange={this.onChangeTag}
                            name="tag"
                            type="text"
                          />
                        </div>

                        <button
                          type="button"
                          onClick={() => {
                            this.addTag(this.state.tag);
                          }}
                          className="button button--positive"
                        >
                          {T.translate("tag.add")}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <h4 className="text-height">{T.translate("category")}:</h4>
                    <Dropdown
                      className={
                        "select__wrap " +
                        (this.state.toggleNoticeCategory ? "open" : "")
                      }
                      isOpen={this.state.toggleNoticeCategory}
                      toggle={() => {
                        this.setState({
                          toggleNoticeCategory: !this.state
                            .toggleNoticeCategory,
                        });
                      }}
                    >
                      <DropdownToggle
                        className="select__button button"
                        disabled={this.state.toggleForm ? "" : "disabled"}
                      >
                        {this.state.notice.companyCategory ? (
                          <span>{this.state.notice.companyCategory.name}</span>
                        ) : (
                          <span className="select__placeholder">
                            {T.translate("select")}…
                          </span>
                        )}
                        <i
                          className={
                            "fa " +
                            (this.state.toggleNoticeCategory
                              ? "fa-caret-up"
                              : "fa-caret-down")
                          }
                        />
                      </DropdownToggle>
                      <DropdownMenu>
                        <ul className="select__list">
                          <li
                            className={
                              "select__item " +
                              (!this.state.notice.companyCategory
                                ? " active"
                                : "")
                            }
                            onClick={() => {
                              this.setState({
                                notice: update(this.state.notice, {
                                  companyCategory: { $set: null },
                                }),
                                toggleNoticeCategory: false,
                              });
                            }}
                          >
                            {T.translate("none")}
                          </li>
                          {this.props.categories
                            ? this.props.categories.map((category) => (
                                <li
                                  key={category.id}
                                  className={
                                    "select__item " +
                                    (this.state.notice.companyCategory &&
                                    this.state.notice.companyCategory.id ===
                                      category.id
                                      ? " active"
                                      : "")
                                  }
                                  onClick={() => {
                                    this.setState({
                                      notice: update(this.state.notice, {
                                        companyCategory: { $set: category },
                                      }),
                                      toggleNoticeCategory: false,
                                    });
                                  }}
                                >
                                  <span
                                    className="card-list__priority"
                                    style={{
                                      backgroundColor: ARGB2HexFilter(
                                        category.color
                                      ),
                                    }}
                                  />
                                  {category.name}
                                </li>
                              ))
                            : null}
                        </ul>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="separator reminder-detail__bottom cf print-hide">
                <div className="line-component">
                  <div className="reminder-detail__due-date">
                    <span>
                      {T.translate("task.due-date")}:{" "}
                      {moment(this.props.noticeDetail.createdAt).format(
                        "MMM DD hh:mm"
                      )}
                    </span>
                  </div>
                </div>
                {this.props.noticeDetail.completedAt !== null ? (
                  <div className="line-component">
                    <div className="reminder-detail__due-date">
                      <span>
                        {T.translate("notices.completed-at")}:{" "}
                        {moment(this.props.noticeDetail.completedAt).format(
                          "MMM DD hh:mm"
                        )}
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="line-component line-component--omega">
                  <button
                    type="button"
                    className="button button--negative"
                    onClick={() => {
                      this.deleteNotice(this.state.notice);
                    }}
                  >
                    <i className="fa fa-trash"></i>
                    <span>{T.translate("delete")}</span>
                  </button>
                  {this.state.toggleForm ? (
                    <button
                      className="button button--positive"
                      type="submit"
                      disabled={
                        this.state.notice.title === "" ? "disabled" : ""
                      }
                    >
                      <i className="fa fa-floppy-o" />
                      {T.translate("save")}
                    </button>
                  ) : null}

                  {this.state.toggleForm ? (
                    <button
                      type="button"
                      className="button button--negative"
                      onClick={() => {
                        this.setState({
                          toggleForm: false,
                          notice: this.props.noticeDetail,
                        });
                      }}
                    >
                      {T.translate("cancel")}
                    </button>
                  ) : null}
                  {!this.state.notice.status && !this.state.toggleForm ? (
                    <button
                      type="button"
                      className="button"
                      onClick={() => {
                        this.setState({
                          toggleForm: !this.state.toggleForm,
                        });
                      }}
                    >
                      <i className="fa fa-edit" />
                      <span>{T.translate("edit")}</span>
                    </button>
                  ) : null}

                  <a
                    onClick={() => {
                      this.props.goBack();
                      if (this.state.saved) {
                        window.location.href = "/list/reminder";
                      }
                    }}
                    className="button reminder-detail__btn--negative"
                  >
                    <i className="fa fa-times" />
                    <span>{T.translate("close")}</span>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalDetail);
